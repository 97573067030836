<template>
  <layout-koffe
    :api="api"
    :sidebar="true"
    position="left"
    :t="$t"
    @onSetLanguage="onSetLanguage"
  >
    <RouterView />
  </layout-koffe>
</template>
<script lang="ts">
import { defineComponent, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useLanguageStore } from "@/stores/language";
import Cookies from "js-cookie";
import "@/helpers/events";
import cookies from "@/helpers/cookies";
import { unLoadStyle } from "koffee-senser-components";
import { GetUserInfoUseCase } from "./users/domain/usecase/getUserInfoUseCase";
import { ChangePasswordUseCase } from "./users/domain/usecase/changePasswordUseCase";
import { UserApiAdapter } from "./users/adapters/api/userApiAdapter";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "HomePage",
  setup() {
    const languageStore = useLanguageStore();
    const userRepository = new UserApiAdapter();
    const use_case = new GetUserInfoUseCase(userRepository);
    const use_case_password = new ChangePasswordUseCase(userRepository);
    const api = {
      getUserInfo: () => use_case.execute(),
      changePassword: (data: any) => use_case_password.execute(data),
    };
    const { locale } = useI18n();

    watch(
      () => languageStore.language.locale,
      (newLocale) => {
        locale.value = newLocale;
      },
      { immediate: true }
    );

    return { languageStore, api };
  },
  mounted() {
    const language = Cookies.get("language") ?? "es";
    this.languageStore.changeLanguage(language);
  },
  methods: {
    togglePermissions() {
      const pathname: string = window.location.pathname;
      const user: any = JSON.parse(cookies.getStorage("permissions") || "");
      const modulesPath = (user.tenant_module as Array<any>).map((v) => v.path);
      const module = modulesPath.filter((v) => pathname.includes(v), 0);
      this.languageStore.changeLanguage("es");
      if (
        (module == null || module == undefined || module.length == 0) &&
        pathname !== "/app"
      ) {
        location.href = "/app";
      }
    },
    onSetLanguage(language: any) {
      this.languageStore.changeLanguage(language);
    },
  },
  created() {
    const router = useRouter();
    const hasAccess = Cookies.get("suite_admin_access");
    if (hasAccess === undefined) {
      unLoadStyle();
      return router.push("/login");
    }
  },
  beforeMount() {
    // const permissionsTreeUpdater: any = inject("permissionsTreeUpdater");
    // permissionsTreeUpdater(cookies);
    // this.togglePermissions();
    window.globalEventEmitter.on("update_permissions", this.togglePermissions);
  },
  unmounted() {
    window.globalEventEmitter.off("update_permissions", this.togglePermissions);
  },
});
</script>
