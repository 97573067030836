import { INoteRepository } from "@/note/ports/iNoteRepository";
import { Note } from "../models/note";

export class UpdateNoteUseCase {
  constructor(private noteRepository: INoteRepository) {}

  async execute(data: any, id: any): Promise<Note> {
    const dataToSend = { ...data };
    dataToSend["determiner_id"] = id;
    return this.noteRepository.updateNote(dataToSend);
  }
}
