// src/interfaces/IIdentificationTypeData.ts
export interface IIdentificationTypeData {
  id: string;
  name: string;
}

export class IdentificationType {
  id: string;
  name: string;

  constructor(data: IIdentificationTypeData | any) {
    this.id = data.id;
    this.name = data.name;
  }
}
