export interface ITypeData {
  id: string;
  name: string;
}

export class Type {
  id: string;
  name: string;

  constructor(data: ITypeData | any) {
    this.id = data.id;
    this.name = data.name;
  }
}
