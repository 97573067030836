export interface INoteData {
  id: string;
  determiner_id: string;
  name: string;
  note: string;
  is_active: boolean;
}

export class Note {
  id: string;
  determiner_id: string;
  name: string;
  note: string;
  is_active: boolean;
  created_by: string;
  created_in: string;
  modified_by: string;
  modified_in: string;

  constructor(data: INoteData | any) {
    this.id = data.id;
    this.determiner_id = data.determiner_id;
    this.name = data.name;
    this.note = data.note;
    this.is_active = data.is_active;
    //nuevos campos
    this.created_by = data.created_by;
    this.created_in = data.created_in;
    this.modified_by = data.modified_by;
    this.modified_in = data.modified_in;
  }
}
