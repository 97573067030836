export default {
  users: {
    determinatorUnassignedSuccessfully: "Dispositivo desasignado exitosamente.",
    customers: "Clientes",
    toast: {
      device: "Determinador",
      detalles_factura: "Detalle factura",
      date: "Fecha: ",
      invoice_path: "Archivo: ",
      number: "Numero factura: ",
      observation: "Observación: ",
      value: "Valor: ",
      warranly_start: "Inicio garantía: ",
      warranly_end: "Fin garantía",
    },
    addCustomer: "Agregar cliente",
    editCustomer: "Editar cliente",
    identification: "Identificación",
    title: "Error",
    text: "No existe un archivo en esta factura",
    enterIdentification: "Ingrese su identificación",
    names: "Nombres",
    enterNames: "Ingrese sus nombres",
    lastNames: "Apellidos",
    enterLastNames: "Ingrese sus apellidos",
    email: "Email",
    enterEmail: "Ingrese su correo electrónico",
    phone: "Teléfono",
    phones: "Teléfono (opcional)",
    enterPhone: "Ingrese su teléfono",
    typeOfIdentification: "Tipo de identificación",
    selectTypeOfIdentification: "Seleccione el tipo de identificación",
    identificationNumber: "Número de identificación",
    role: "Rol",
    selectRole: "Seleccione el rol",
    status: "Estado",
    selectStatus: "Seleccione el estado",
    country: "País",
    department: "Departamento",
    city: "Ciudad",
    created_by: "Creador",
    created_in: "Fecha creación",
    modified_by: "Editor",
    is_active: "Estado",
    address: "Dirección",
    enterAddress: "Ingrese su dirección",
    modified_in: "Fecha edición",
    table: {
      identification: "Identificación",
      full_name: "Nombre completo",
      email: "Correo electrónico",
      created_in: "Fecha de registro",
      phone: "Teléfono",
      actions: "Acciones",
      country_name: "País",
      state_name: "Departamento",
      city_name: "Ciudad",
      rol_name: "Rol",
      country_department_city: "Ubicación",
      is_active: "Estado",
      viewCustomer: "Ver cliente",
      active: "Activo",
      inactive: "Inactivo",
    },
    customerSuccessfullyCreated: "Cliente creado correctamente",
    customerSuccessfullyUpdated: "Cliente actualizado correctamente",
    determinatorCorrectlyAssigned: "Dispositivo asignado correctamente",
    changePassword: "Cambiar contraseña",
    password: "Contraseña",
    validations: {
      already_identification_user:
        "Ya existe un usuario con esa identificacion",
      the_verify_id_already_exists: "El id verificacion ya existe",
      already_email_user: "Ya existe un usuario con ese correo",
    },
  },
  usersDetails: {
    basicData: "Datos básicos",
    areYouSureUnassignDevice:
      "¿Estás seguro de que quieres desasignar este dispositivo?",
    downloadFile: "¿Quieres descargar el archivo?",
  },
};
