<template>
  <div class="contact-info__container list_user-content">
    <!--Component container-->
    <div class="contact-info__header">
      <div class="contact-info__header__path" @click="back">
        <img :src="ICONS.LEFT_ARROW" alt="back-icon" />
        <span class="font-s">{{ $t("main.common.back") }}</span>
      </div>
      <div class="contact-info__header--left">
        <div class="contact-info__header__avatar-container" v-if="determiner">
          <co-avatar size="md" type="primary" data-testid="business_name">
            <span v-if="determiner.serial" class="">{{
              (determiner?.serial ?? "")[0].toUpperCase()
            }}</span>
          </co-avatar>
          <span class="contact-name font-l-bold">
            {{
              (determiner?.code_id ?? "") + " - " + (determiner?.serial ?? "")
            }}
          </span>
        </div>
        <co-tooltip :label="$t('main.common.edit')" position="right">
          <co-button type="icon" @click="editContactInfo">
            <img :src="ICONS.EDIT" alt="icon-edit" />
          </co-button>
        </co-tooltip>
      </div>
      <div class="contact-info__header--right"></div>
    </div>
    <div class="contact-info__content">
      <div class="sub-component__main-container">
        <!--Component container-->
        <!--Left content-->
        <div class="sub-component__left-container">
          <div class="contact-info__container">
            <div class="contact-info__container__basic-data-container">
              <div class="basic-data">
                <div class="sub-component__item">
                  <div class="sub-component__item__header">
                    <span class="font-l-bold">{{
                      $t("main.determinerDetails.basicData")
                    }}</span>
                  </div>
                  <div class="sub-component__item__body">
                    <co-loading v-if="isLoadingBasicData" specific></co-loading>

                    <div v-if="!isLoadingBasicData && determiner">
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.code") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.code_id
                              ? determiner.code_id
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.verify_id") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.verify_id
                              ? determiner.verify_id
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.serial") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.serial
                              ? determiner.serial
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.model") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.model
                              ? determiner.model
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.trackNumber") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.track_number
                              ? determiner.track_number
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.type") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.type_name
                              ? determiner.type_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.status") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.state_name
                              ? determiner.state_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.owner") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.owner
                              ? determiner.owner
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.user") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.owner
                              ? determiner.owner
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.send_date") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span v-if="determiner && determiner.send_date">
                            {{
                              new FormatDate(
                                $t("main.common.language"),
                                determiner.send_date
                              ).getFullDateString()
                            }}
                          </span>
                          <span v-else>
                            {{
                              determiner.send_date
                                ? determiner.send_date
                                : $t("main.common.notRegistered")
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{
                            $t("main.determiners.estimate_delivery_date")
                          }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span
                            v-if="
                              determiner && determiner.estimate_delivery_date
                            "
                          >
                            {{
                              new FormatDate(
                                $t("main.common.language"),
                                determiner.estimate_delivery_date
                              ).getFullDateString()
                            }}
                          </span>
                          <span v-else>
                            {{
                              determiner.estimate_delivery_date
                                ? determiner.estimate_delivery_date
                                : $t("main.common.notRegistered")
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.deliverydate") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span v-if="determiner && determiner.delivery_date">
                            {{
                              new FormatDate(
                                $t("main.common.language"),
                                determiner.delivery_date
                              ).getFullDateString()
                            }}
                          </span>
                          <span v-else>
                            {{
                              determiner.delivery_date
                                ? determiner.delivery_date
                                : $t("main.common.notRegistered")
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.is_active") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.is_active
                              ? determiner.is_active
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <!-- nuevos campos -->
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.created_by") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.created_by
                              ? determiner.created_by
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.created_in") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span v-if="determiner && determiner.created_in">
                            {{
                              new FormatDate(
                                $t("main.common.language"),
                                determiner.created_in
                              ).getFullDateString()
                            }}
                          </span>
                          <span v-else>
                            {{
                              determiner.created_in
                                ? determiner.created_in
                                : $t("main.common.notRegistered")
                            }}
                          </span>
                        </div>
                      </div>
                      <!-- nuevos campos -->
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.modified_by") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            determiner.modified_by
                              ? determiner.modified_by
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.determiners.modified_in") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span v-if="determiner && determiner.modified_in">
                            {{
                              new FormatDate(
                                $t("main.common.language"),
                                determiner.modified_in
                              ).getFullDateString()
                            }}
                          </span>
                          <span v-else>
                            {{
                              determiner.modified_in
                                ? determiner.modified_in
                                : $t("main.common.notRegistered")
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="!isLoadingBasicData && !determiner"
                      class="no-data-container"
                    >
                      {{ $t("No device information") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Right content-->
        <div class="sub-component__right-container">
          <div class="headres-content">
            <span
              class="activities-and-comments__header__title-container__title sub-component__item__header font-l-bold"
              >{{ $t("main.notes.notes") }}</span
            >
            <co-button
              size="sm"
              type="primary"
              @trigger-click="addNote"
              dataTestId="invite_note_button"
            >
              <span class="users-business__header__span">
                {{ $t("main.notes.addNote") }}
              </span>
              <img
                class="list-contact__header__icon"
                :src="ICONS.PLUS"
                alt="icon_add"
              />
            </co-button>
          </div>
          <ListNote
            v-if="id"
            :determiner_id="id"
            @editNoteInfo="editNoteInfo"
            ref="listNote"
          />
        </div>
      </div>
    </div>
    <AddOrEditDeterminerComponent
      dataTestId="invite_user_modal"
      :isEdit="isEdit"
      @onSave="() => getDeterminerData()"
      ref="refuser"
    />
    <AddOrEditNoteComponent
      :determiner_id="id"
      dataTestId="invite_note_modal"
      :isEdit="isEditNote"
      ref="refnotes"
      @onClose="() => (isEditNote = false)"
      @onSave="() => refreshListNote()"
    />
  </div>
</template>
<script lang="ts">
import { FormatDate, ICONS } from "koffee-senser-components";
import { defineComponent, inject } from "vue";
import router from "@/router";
import AddOrEditDeterminerComponent from "./AddOrEditDeterminerComponent.vue";
import AddOrEditNoteComponent from "../../note/components/AddOrEditNoteComponent.vue";
import { GetDeterminerInfoUseCase } from "../domain/getDeterminerInfoUseCase";
import { useCommonModal } from "@/stores/modals";
import { ListDeterminersUseCase } from "../../determiners/domain/listDeterminersUseCase";
import { DeterminerApiAdapter } from "../../determiners/adapters/api/determinerApiAdapter";
import type {
  Filters,
  Header,
  DatatableText,
} from "koffee-senser-components/src/interfaces/data-table.interface";
import ListNote from "../../note/components/ListNote.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DeterminerDetail",
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    const route = useRoute();
    return { modalStore, toast, can, FormatDate, ICONS, route };
  },
  components: {
    AddOrEditDeterminerComponent,
    AddOrEditNoteComponent,
    ListNote,
  },
  data() {
    return {
      determiner: null as any,
      isLoadingBasicData: true,
      headers: [
        { text: "Codigo", value: "code_id" },
        { text: "ID Verificacion", value: "verify_id" },
        { text: "Serial", value: "serial" },
        { text: "Fecha Estimada Entrega", value: "estimate_delivery_date" },
        { text: "Fecha Envio", value: "delivery_date" },
        { text: "Fecha Entrega", value: "send_date" },
        { text: "Numero Guia", value: "track_number" },
        { text: "Modelo", value: "model" },
        { text: "Tipo", value: "type_name" },
        { text: "Estado", value: "state_name" },
        { text: "Dueño", value: "owner" },
        { text: "Usuario", value: "user_name" },
        { text: "Modelo", value: "model" },
        { text: "Creador", value: "created_by" },
        { text: "Fecha creacion", value: "created_in" },
        { text: "Editor", value: "modified_by" },
        { text: "Fecha edicion", value: "modified_in" },
      ] as Header[],
      updater: 0,
      filters: {} as Filters,
      isEdit: false,
      isEditNote: false,
      id: "",
    };
  },
  mounted() {
    this.id = this.route.params.id as string;
    this.getDeterminerData();
  },
  computed: {
    headersC(): any[] {
      return this.headers.map((v) => {
        const name = this.$t("main.determiners.table." + v.value);
        return {
          ...v,
          text: "isAditional" in v ? v.text : name,
        };
      });
    },
    texts(): DatatableText {
      return {
        rowsPerpage: this.$t("main.common.table.text.rowsPerpage"),
        emptyMessage: this.$t("main.common.table.text.emptyMessage"),
        of: this.$t("main.common.table.text.of"),
        results: this.$t("main.common.table.text.results"),
        first: this.$t("main.common.table.text.first"),
        last: this.$t("main.common.table.text.last"),
      };
    },
  },
  methods: {
    refreshListNote() {
      (this.$refs["listNote"] as any).getData();
    },
    back() {
      router.push("/app/device");
    },
    addDeterminer() {
      this.modalStore.changeMultiModalState(
        "showEditOrCreateDeterminerModal",
        true
      );
    },
    editContactInfo() {
      this.modalStore.changeMultiModalState(
        "showEditOrCreateDeterminerModal",
        true
      );
      this.isEdit = true;

      if (this.determiner) {
        (
          this.$refs.refuser as { setDeterminer: (determiner: any) => void }
        ).setDeterminer(this.determiner);
      }
    },
    addNote() {
      this.modalStore.changeMultiModalState("showEditOrCreateNoteModal", true);
    },
    editNoteInfo(note: any) {
      this.modalStore.changeMultiModalState("showEditOrCreateNoteModal", true);
      this.isEditNote = true;
      (this.$refs["refnotes"] as any).setNotes(note);
    },
    getDeterminerData() {
      const userRepository = new DeterminerApiAdapter();
      const getUserInfoUseCase = new GetDeterminerInfoUseCase(userRepository);
      this.isLoadingBasicData = true;
      getUserInfoUseCase
        .executeBy(this.id)
        .then((response) => {
          this.determiner = response;
          this.isLoadingBasicData = false;
        })
        .catch((error) => {
          console.error("Error fetching determiner data:", error);
          this.isLoadingBasicData = false;
        });
    },
    listDeterminerApi(restApiUrl: any, axiosHeader: any) {
      const determinerRepository = new DeterminerApiAdapter();
      const listDeterminersUseCase = new ListDeterminersUseCase(
        determinerRepository
      );
      return listDeterminersUseCase.execute(restApiUrl, axiosHeader);
    },
  },
});
</script>
