export default {
  common: {
    language: "en",
    add: "Add",
    edit: "Edit",
    cancel: "Cancel",
    save: "Save",
    download: "Download",
    upload: "Upload",
    notRegistered: "--Not registred--",
    viewdeterminer: "View device",
    viewEdit: "View data",
    viewDocument: "Download document",
    viewUnAsigned: "Unassign",
    successfulProcess: "Successful process",
    table: {
      text: {
        rowsPerpage: "Rows per page",
        emptyMessage: "No available data",
        of: "of",
        results: "Results",
        first: "First",
        last: "Last",
      },
    },
    toasts: {
      success_title: "Successful process!",
      error_title: "Sorry!",
      missingDataFilledIn: "Empty, complete fields",
      warning_title: "Warning.",
      success_note: "Note added correctly",
      edit_note: "Note edit correctly",
    },
    back: "Back",
  },
};
