import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "data-testid": "list_permission" }
const _hoisted_2 = {
  autocomplete: "off",
  onsubmit: "return false;"
}
const _hoisted_3 = { class: "row_input_permissions" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_co_select = _resolveComponent("co-select")!
  const _component_co_button = _resolveComponent("co-button")!
  const _component_co_loading = _resolveComponent("co-loading")!
  const _component_co_accordionTable = _resolveComponent("co-accordionTable")!
  const _component_co_accordion = _resolveComponent("co-accordion")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_co_select, {
          value: _ctx.form.rol_id,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.rol_id) = $event)),
          placeholder: _ctx.$t('main.users.selectRole'),
          "placeholder-search": _ctx.$t('components.web.common.search'),
          label: _ctx.$t('main.users.role'),
          items: _ctx.rols,
          itemText: "name",
          position: "bottom",
          time: "",
          size: "sm",
          required: true
        }, null, 8, ["value", "placeholder", "placeholder-search", "label", "items"]),
        _createVNode(_component_co_button, {
          size: "sm",
          type: "primary",
          disabled: _ctx.disabled,
          onTriggerClick: _ctx.save
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("main.common.save")) + " ", 1),
            _createElementVNode("img", {
              class: "invite-note-modal__icon-save",
              src: _ctx.ICONS.MAIL,
              alt: "Email"
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["disabled", "onTriggerClick"])
      ])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_co_loading, {
          key: 0,
          specific: ""
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id
      }, [
        _createVNode(_component_co_accordion, {
          open: item.open,
          "onUpdate:open": ($event: any) => ((item.open) = $event),
          icon: _ctx.ICONS.BUSINESS,
          label: item.name,
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.components, (action, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createVNode(_component_co_accordionTable, {
                  name: action.name,
                  code: action.id,
                  checked: action.is_check,
                  "onUpdate:checked": ($event: any) => ((action.is_check) = $event),
                  disabled: _ctx.disabled
                }, null, 8, ["name", "code", "checked", "onUpdate:checked", "disabled"])
              ]))
            }), 128))
          ]),
          _: 2
        }, 1032, ["open", "onUpdate:open", "icon", "label", "disabled"])
      ]))
    }), 128))
  ]))
}