import { IPermissionsRepository } from "@/permissions/ports/iPermissionsRepository";
import { Permissions } from "../models/permissions";

export class ListPermissionsUseCase {
  constructor(private permissionsRepository: IPermissionsRepository) {}

  async execute(id: any, isMobile: boolean): Promise<Permissions[]> {
    return this.permissionsRepository.getPermissions(id, isMobile);
  }
}
