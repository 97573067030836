<template>
  <co-modal
    class="add-edit-note-modal"
    v-model:value="modalActive"
    size="lg"
    :width="600"
    @close="close"
    dataTestId="invite_note_modal"
  >
    <template #title>
      <h1 class="add-edit-note-modal__title">
        {{ isEdit ? $t("main.notes.editNote") : $t("main.notes.addNote") }}
      </h1>
    </template>
    <template #body>
      <form
        class="add-edit-note-modal__form"
        autocomplete="off"
        onsubmit="return false;"
      >
        <div class="row_input_note">
          <co-input
            type="text"
            v-model="form.name"
            :label="$t('main.notes.note')"
            :placeholder="$t('main.notes.enterTitleNote')"
            size="md"
            block
            :required="true"
            :error="errors.name"
          />
        </div>
        <div>
          <div>
            <co-TextArea v-model:modelValue="form.note" :error="errors.note" />
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <co-button size="sm" type="cancel" @trigger-click="close">
        {{ $t("main.common.cancel") }}
        <em class="mdi mdi-close"></em>
      </co-button>
      <co-button
        size="sm"
        type="primary"
        @trigger-click="save"
        :disabled="isSaving"
      >
        {{ $t("main.common.save") }}
        <img
          class="invite-note-modal__icon-save"
          :src="ICONS.MAIL"
          alt="Email"
        />
      </co-button>
    </template>
  </co-modal>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonModal } from "@/stores/modals";
import { AddNoteUseCase } from "../domain/usecase/addNoteUseCase";
import { FormNote } from "../domain/models/formNotes";
import type { IFormNoteData } from "../domain/models/formNotes";
import { NoteApiAdapter } from "../adapters/api/noteApiAdapter";
import { UpdateNoteUseCase } from "../domain/usecase/updateNoteUseCase";
import { Note } from "../domain/models/note";
import { ICONS } from "koffee-senser-components";

export default defineComponent({
  name: "AddOrEditNoteComponent",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    determiner_id: {
      type: String,
      default: "",
    },
  },
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, ICONS };
  },
  data() {
    return {
      type: [],
      isSaving: false,
      isLoading: false,
      form: {
        name: "" as string,
        note: "" as string,
        is_active: true,
      } as IFormNoteData | FormNote,
      errors: {
        name: false,
        note: false,
      },
      notes: [] as Note[],
    };
  },
  computed: {
    modalActive: {
      get(): boolean {
        return this.modalStore.modals.showEditOrCreateNoteModal;
      },
      set(v: boolean) {
        this.modalStore.changeMultiModalState("showEditOrCreateNoteModal", v);
      },
    },
  },
  methods: {
    close() {
      this.modalStore.changeMultiModalState("showEditOrCreateNoteModal", false);
      this.errors.name = false;
      this.errors.note = false;
      this.form = {
        determiner_id: "" as string,
        name: "" as string,
        note: "" as string,
        is_active: true,
      };
      this.isSaving = false;
      setTimeout(() => this.$emit("onClose"), 300);
    },
    setNotes(note: any) {
      this.form = { ...note };
    },
    isFormCompleted(): any {
      this.errors.name = this.form.name === "";
      this.errors.note = this.form.note === "";
      return this.form.name && this.form.note;
    },
    save() {
      this.isSaving = true;
      const isFormCompleted = this.isFormCompleted();
      if (isFormCompleted) {
        const noteRepository = new NoteApiAdapter();
        let usecase = this.isEdit
          ? new UpdateNoteUseCase(noteRepository)
          : new AddNoteUseCase(noteRepository);
        usecase
          .execute(this.form, this.determiner_id)
          .then(() => {
            const successMessage = this.isEdit
              ? this.$t("main.common.toasts.edit_note")
              : this.$t("main.common.toasts.success_note");
            this.toast({
              type: "success",
              title: this.$t("main.common.toasts.success_title"),
              text: successMessage,
              size: "sm",
            });
            this.isSaving = false;
            this.$emit("onSave");
            this.close();
          })
          .catch(() => {
            const errorMessage = this.isEdit
              ? this.$t("main.common.toasts.error_edit_title")
              : this.$t("main.common.toasts.error_title");

            this.toast({
              type: "error",
              title: errorMessage,
              text: errorMessage || this.$t("main.common.toasts.unknown_error"),
              size: "sm",
            });
            this.isSaving = false;
          });
      } else {
        this.toast({
          type: "error",
          title: this.$t("main.common.toasts.error_title"),
          text: this.$t("main.common.toasts.missingDataFilledIn"),
          size: "sm",
        });
        this.isSaving = false;
      }
    },
  },
});
</script>
