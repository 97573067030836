import { User } from "../models/user";
import { IUserRepository } from "../../ports/iUserRepository";

export class AssingDeterminatorUserUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(determinador: any, idUser: any, data: any): Promise<User> {
    return this.userRepository.asignDeterminer(determinador.id, idUser, data);
  }
}
