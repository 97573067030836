export default {
  notes: {
    notes: "Notas",
    note: "Nota",
    addNote: "Agregar nota",
    editNote: "Editar nota",
    enterTitleNote: "Ingrese título de la nota",
    status: "Estado",
    writeContentHere: "Escribe tu contenido aquí...",
  },
};
