<template>
  <div class="list_agents-content">
    <div class="headres-content">
      <form
        autocomplete="off"
        onsubmit="return false;"
        style="max-width: 240px"
      >
        <co-input
          v-model="search"
          type="search"
          size="md"
          :placeholder="$t('components.web.common.search')"
          block
          dataTestId="input_search"
        />
      </form>
      <co-button
        size="sm"
        type="primary"
        @trigger-click="addAgents"
        dataTestId="invite_agents_button"
      >
        <span class="agents-business__header__span">
          {{ $t("main.common.add") }}
        </span>
        <img
          class="list-contact__header__icon"
          :src="ICONS.PLUS"
          alt="icon_add"
        />
      </co-button>
    </div>
    <div class="table-agents">
      <co-data-table
        class="users-business__table"
        api="/api/agents/"
        :filtros="filters"
        :headers="headersC"
        :updater="updater"
        :texts="texts"
        :promiseApi="listAgentsApi"
        data-testid="agents_list"
      >
        <template #loading>
          <slot name="loading">
            <img
              src="https://koffeesenserstorage.blob.core.windows.net/koffeesenser-icons/loading_coffee.gif"
              alt=""
              srcset=""
            />
          </slot>
        </template>
        <template #item-created_in="item">
          {{
            new FormatDate(
              $t("main.common.language"),
              item.item.created_in
            ).getFullDate()
          }}
        </template>
        <template #item-actions="item">
          <div
            class="agents-business__table__actions"
            v-if="!item.item.is_superuser"
          >
            <img
              style="cursor: pointer"
              alt="view-user"
              :src="ICONS.EDIT"
              class="agents-business__table__actions__icon"
              @click="editAgents(item.item)"
            />
          </div>
        </template>
      </co-data-table>
    </div>
    <AddOrEditAgentsModal
      dataTestId="invite_agents_modal"
      :isEdit="isEdit"
      @onSave="() => updater++"
      @onClose="() => (isEdit = false)"
      ref="refuser"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonModal } from "@/stores/modals";
import type {
  Filters,
  Header,
  DatatableText,
} from "koffee-senser-components/src/interfaces/data-table.interface";
import { FormatDate } from "koffee-senser-components";
import { AgentsApiAdapter } from "../adapters/agentsApiAdapter";
import { ListAgentsUseCase } from "../domain/usecase/listAgentsUseCase";
import { Agents } from "../domain/models/agents";
import AddOrEditAgentsModal from "./AddOrEditAgentsModal.vue";
import { ICONS } from "koffee-senser-components";

export default defineComponent({
  name: "ListAgents",
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, FormatDate, ICONS };
  },
  components: {
    AddOrEditAgentsModal,
  },
  data() {
    return {
      isEdit: false,
      headers: [
        { text: "Identificacion", value: "identification" },
        { text: "Nombre Completo", value: "full_name" },
        { text: "Fecha creacion", value: "created_in" },
        { text: "Rol", value: "rol_name", width: 300 },
        { text: "Acciones", value: "actions" },
      ] as Header[],
      filters: {} as Filters,
      updater: 0,
      search: "" as string,
    };
  },
  methods: {
    listAgentsApi(restApiUrl: any, axiosHeader: any) {
      const agentsRepository = new AgentsApiAdapter();
      const listAgentsUseCase = new ListAgentsUseCase(agentsRepository);
      return listAgentsUseCase.execute(restApiUrl, axiosHeader);
    },
    addAgents() {
      this.modalStore.changeMultiModalState(
        "showEditOrCreateAgentsModal",
        true
      );
    },
    editAgents(itm: Agents) {
      this.modalStore.changeMultiModalState(
        "showEditOrCreateAgentsModal",
        true
      );
      this.isEdit = true;
      (this.$refs["refuser"] as any).setAgents(itm);
    },
  },
  computed: {
    headersC(): any[] {
      return this.headers.map((v) => {
        const name = this.$t("main.users.table." + v.value);
        return {
          ...v,
          text: "isAditional" in v ? v.text : name,
        };
      });
    },
    texts(): DatatableText {
      return {
        rowsPerpage: this.$t("main.common.table.text.rowsPerpage"),
        emptyMessage: this.$t("main.common.table.text.emptyMessage"),
        of: this.$t("main.common.table.text.of"),
        results: this.$t("main.common.table.text.results"),
        first: this.$t("main.common.table.text.first"),
        last: this.$t("main.common.table.text.last"),
      };
    },
  },
  watch: {
    search(value) {
      this.filters["search"] = value;
      this.filters = { ...this.filters };
    },
  },
});
</script>
