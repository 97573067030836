import { defineStore } from "pinia";

export const useLanguageStore = defineStore("languageStore", {
  state: () => ({
    language: {
      locale: "es",
    },
  }),
  actions: {
    changeLanguage(value: string) {
      this.language.locale = value;
    },
  },
});
