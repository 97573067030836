export default {
  permissions: {
    permissions: "Permisos",
    permissionsWeb: "Permisos web",
    PermissionsMobile: "Permisos móvil",
    toasts: {
      success_permissions: "Permisos agregado correctamente",
    },
  },
};
