import agents from "./agents";
import determiners from "./determiners";
import notes from "./notes";
import permissions from "./permissions";
import users from "./users";

export default {
  es: {
    ...determiners.es,
    ...users.es,
    ...notes.es,
    ...agents.es,
    ...permissions.es,
  },
  en: {
    ...determiners.en,
    ...users.en,
    ...notes.en,
    ...agents.en,
    ...permissions.en,
  },
};
