import { IAgentsRepository } from "@/agents/ports/iAgentsRepository";
import { IPaginatedResponse } from "@/interfaces/IPaginatedResponse";
import { Agents } from "../models/agents";

export class ListAgentsUseCase {
  constructor(private agentsRespository: IAgentsRepository) {}

  async execute(
    restApiUrl: any,
    axiosHeaders: any
  ): Promise<IPaginatedResponse<Agents>> {
    return this.agentsRespository.getAgents(restApiUrl, axiosHeaders);
  }
}
