<template>
  <div class="list_user-content" data-testid="contacts_view">
    <div class="headres-content">
      <form
        autocomplete="off"
        onsubmit="return false;"
        style="max-width: 240px"
      >
        <co-input
          v-model="search"
          type="search"
          size="md"
          :placeholder="$t('components.web.common.search')"
          block
          dataTestId="input_search"
        />
      </form>
      <div class="content-buttons">
        <co-button
          class="products__content__header__right-content--import"
          size="sm"
          type="cancel"
          @trigger-click="importDevices"
          data-testId="import-product-modal"
        >
          <span>{{ $t("main.determinerImport.importDevices") }}</span>
          <img :src="ICONS.EXCEL" alt="" />
        </co-button>
        <co-button
          size="sm"
          type="primary"
          @trigger-click="addDeterminer"
          dataTestId="invite_user_button"
        >
          <span class="users-business__header__span">
            {{ $t("main.common.add") }}
          </span>
          <img
            class="list-contact__header__icon"
            :src="ICONS.PLUS"
            alt="icon_add"
          />
        </co-button>
      </div>
    </div>
    <div class="list-contact__data-table">
      <co-data-table
        class="list-contact__table"
        api="/api/determiner/"
        :filtros="filters"
        :headers="headersC"
        :updater="updater"
        :texts="texts"
        :promiseApi="listDeterminerApi"
        data-testid="determiners_list"
      >
        <template #loading>
          <slot name="loading">
            <img
              src="https://koffeesenserstorage.blob.core.windows.net/koffeesenser-icons/loading_coffee.gif"
              alt=""
              srcset=""
            />
          </slot>
        </template>
        <template #item-send_date="item">
          <div v-if="item.item.send_date">
            {{
              new FormatDate(
                $t("main.common.language"),
                item.item.send_date
              ).getFullDateString()
            }}
          </div>
        </template>
        <template #item-delivery_date="item">
          <div v-if="item.item.delivery_date">
            {{
              new FormatDate(
                $t("main.common.language"),
                item.item.delivery_date
              ).getFullDateString()
            }}
          </div>
        </template>
        <template #item-created_in="item">
          <div v-if="item.item.created_in">
            {{
              new FormatDate(
                $t("main.common.language"),
                item.item.created_in
              ).getFullDate()
            }}
          </div>
        </template>
        <template #item-actions="item">
          <div class="Determiners-business__table__actions">
            <co-tooltip
              class="Determiners-business__table__actions__view"
              :label="$t('main.common.viewdeterminer')"
              position="top"
            >
              <img
                style="cursor: pointer"
                alt="view-determiner"
                :src="ICONS.EYE"
                class="determiners-business__table__actions__icon"
                @click="onEdit(item.item)"
              />
            </co-tooltip>
          </div>
        </template>
      </co-data-table>
    </div>
    <AddOrEditDeterminerComponent
      dataTestId="invite_determiner_modal"
      @onSave="refreshData"
    />
    <ImportDevices @onSuccess="refreshData" />
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { Determiner } from "../domain/determiner";
import { useCommonModal } from "@/stores/modals";
import AddOrEditDeterminerComponent from "./AddOrEditDeterminerComponent.vue";
import ImportDevices from "./ImportDevices.vue";
import { DeterminerApiAdapter } from "../adapters/api/determinerApiAdapter";
import type {
  Filters,
  Header,
  DatatableText,
} from "koffee-senser-components/src/interfaces/data-table.interface";
import { ListDeterminersUseCase } from "../domain/listDeterminersUseCase";
import { FormatDate } from "koffee-senser-components";
import router from "@/router";
import { ICONS } from "koffee-senser-components";

export default defineComponent({
  name: "ListDeterminers",
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, FormatDate, ICONS };
  },
  components: {
    AddOrEditDeterminerComponent,
    ImportDevices,
  },
  data() {
    return {
      headers: [
        { text: "Codigo", value: "code_id" },
        { text: "ID Verificacion", value: "verify_id" },
        { text: "Serial", value: "serial" },
        { text: "Modelo", value: "model" },
        { text: "Fecha Creacion", value: "created_in" },
        { text: "Número de Guía", value: "track_number" },
        { text: "Estado", value: "state_name", width: 95 },
        { text: "Dueño", value: "owner" },
        { text: "Tipo", value: "type_name", width: 120 },
        { text: "Acciones", value: "actions", sortable: false },
      ] as Header[],
      filters: {} as Filters,
      updater: 0,
      search: "" as string,
    };
  },
  methods: {
    importDevices() {
      this.modalStore.changeMultiModalState("showImportDevices", true);
    },
    listDeterminerApi(restApiUrl: any, axiosHeader: any) {
      const determinerRepository = new DeterminerApiAdapter();
      const listDeterminersUseCase = new ListDeterminersUseCase(
        determinerRepository
      );
      return listDeterminersUseCase.execute(restApiUrl, axiosHeader);
    },
    addDeterminer() {
      this.modalStore.changeMultiModalState(
        "showEditOrCreateDeterminerModal",
        true
      );
    },
    onEdit(itm: Determiner) {
      router.push("device/" + itm.id);
    },
    refreshData() {
      // Increment the updater to trigger a refresh of the data table
      this.updater++;
    },
  },
  computed: {
    headersC(): any[] {
      return this.headers.map((v) => {
        const name = this.$t("main.determiners.table.device." + v.value);
        return {
          ...v,
          text: "isAditional" in v ? v.text : name,
        };
      });
    },
    texts(): DatatableText {
      return {
        rowsPerpage: this.$t("main.common.table.text.rowsPerpage"),
        emptyMessage: this.$t("main.common.table.text.emptyMessage"),
        of: this.$t("main.common.table.text.of"),
        results: this.$t("main.common.table.text.results"),
        first: this.$t("main.common.table.text.first"),
        last: this.$t("main.common.table.text.last"),
      };
    },
  },
  watch: {
    search(value) {
      this.filters["search"] = value;
      this.filters = { ...this.filters };
    },
  },
});
</script>
