import { Status } from "./status";
import { IDeterminerRepository } from "../ports/iDeterminerRepository";

export class ListStatusDeterminerUseCase {
  constructor(private determinerRepository: IDeterminerRepository) {}

  async execute(): Promise<Status[]> {
    return this.determinerRepository.getStatus();
  }
}
