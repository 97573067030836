export default {
  common: {
    language: "es",
    add: "Añadir",
    edit: "Editar",
    cancel: "Cancelar",
    save: "Guardar",
    download: "Descargar",
    upload: "Cargar",
    notRegistered: "-No registrado-",
    viewdeterminer: "Vista dispositivo",
    viewEdit: "Ver datos",
    viewDocument: "Descargar documento",
    viewUnAsigned: "Desasignar",
    successfulProcess: "Proceso exitoso",
    table: {
      text: {
        rowsPerpage: "Filas por página",
        emptyMessage: "No hay datos disponibles",
        of: "de",
        results: "Resultados",
        first: "Primero",
        last: "Último",
      },
    },
    toasts: {
      success_title: "Proceso exitoso!",
      error_title: "¡Lo siento!",
      missingDataFilledIn: "Campos vacios, completalos",
      unknown_error: "Se produjo un error desconocido.",
      error_edit_title: "Error durante la edición.",
      warning_title: "Advertencia.",
      success_note: "Nota agregada correctamente",
      edit_note: "Nota editada correctamente",
    },
    back: "Volver",
  },
};
