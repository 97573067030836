<template>
  <div class="list_user-content">
    <div class="headres-content">
      <form
        autocomplete="off"
        onsubmit="return false;"
        style="max-width: 240px"
      >
        <co-input
          v-model="search"
          type="search"
          size="md"
          :placeholder="$t('components.web.common.search')"
          block
          dataTestId="input_search"
        />
      </form>
      <co-button
        size="sm"
        type="primary"
        @trigger-click="addUser"
        dataTestId="invite_user_button"
      >
        <span class="users-business__header__span">
          {{ $t("main.common.add") }}
        </span>
        <img
          class="list-contact__header__icon"
          :src="ICONS.PLUS"
          alt="icon_add"
        />
      </co-button>
    </div>
    <div class="table-users">
      <co-data-table
        class="users-business__table"
        api="/api/users/"
        :filtros="filters"
        :headers="headersC"
        :updater="updater"
        :texts="texts"
        :promiseApi="listUserApi"
        data-testid="users_list"
      >
        <template #loading>
          <slot name="loading">
            <img
              src="https://koffeesenserstorage.blob.core.windows.net/koffeesenser-icons/loading_coffee.gif"
              alt="loading coffee"
            />
          </slot>
        </template>
        <template #item-phone="item">
          <span v-if="item.item.phone">{{ item.item.phone.phone }}</span>
        </template>
        <template #item-created_in="item">
          {{
            new FormatDate(
              $t("main.common.language"),
              item.item.created_in
            ).getFullDate()
          }}
        </template>
        <template #item-actions="item">
          <div
            class="users-business__table__actions"
            v-if="!item.item.is_superuser"
          >
            <co-tooltip
              class="Determiners-business__table__actions__view"
              :label="$t('main.users.table.viewCustomer')"
              position="top"
            >
              <img
                style="cursor: pointer"
                alt="view-user"
                :src="ICONS.EYE"
                class="users-business__table__actions__icon"
                @click="onEdit(item.item)"
              />
            </co-tooltip>
          </div>
        </template>
        <template #item-is_active="item">
          <span>{{ isActiveText(item.item.is_active) }}</span>
        </template>
      </co-data-table>
    </div>
    <AddOrEditUserModal
      dataTestId="invite_user_modal"
      @onSave="() => updater++"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import router from "@/router";
import { User } from "../domain/models/user";
import { useCommonModal } from "@/stores/modals";

import AddOrEditUserModal from "./AddOrEditUserModal.vue";

import { ListUsersUseCase } from "../domain/usecase/listUsersUseCase";

import { UserApiAdapter } from "../adapters/api/userApiAdapter";
import type {
  Filters,
  Header,
  DatatableText,
} from "koffee-senser-components/src/interfaces/data-table.interface";
import { FormatDate } from "koffee-senser-components";
import { ICONS } from "koffee-senser-components";

export default defineComponent({
  name: "ListUsers",
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, FormatDate, ICONS };
  },
  components: {
    AddOrEditUserModal,
  },
  data() {
    return {
      headers: [
        { text: "Identificacion", value: "identification", width: 50 },
        { text: "Nombre Completo", value: "full_name", width: 240 },
        { text: "Rol", value: "rol_name", width: 90 },
        { text: "Fecha Regisro", value: "created_in", width: 100 },
        { text: "Ubicacion", value: "country_department_city", width: 230 },
        { text: "Teléfono", value: "phone", width: 104 },
        { text: "Activo", value: "is_active", width: 64 },
        { text: "Acciones", value: "actions", width: 20 },
      ] as Header[],
      filters: {
        search: "",
        create_in: "",
        sort: "asc",
      } as Filters,
      updater: 0,
      search: "" as string,
    };
  },
  methods: {
    listUserApi(restApiUrl: any, axiosHeader: any) {
      const userRepository = new UserApiAdapter();
      const listUsersUseCase = new ListUsersUseCase(userRepository);
      return listUsersUseCase.execute(restApiUrl, axiosHeader);
    },
    addUser() {
      this.modalStore.changeMultiModalState("showEditOrCreateUserModal", true);
    },
    onEdit(itm: User) {
      router.push("users/" + itm.id);
    },
    isActiveText(isActive: boolean): string {
      return isActive
        ? this.$t("main.users.table.active")
        : this.$t("main.users.table.inactive");
    },
  },
  computed: {
    headersC(): any[] {
      return this.headers.map((v) => {
        const name = this.$t("main.users.table." + v.value);
        return {
          ...v,
          text: "isAditional" in v ? v.text : name,
        };
      });
    },
    texts(): DatatableText {
      return {
        rowsPerpage: this.$t("main.common.table.text.rowsPerpage"),
        emptyMessage: this.$t("main.common.table.text.emptyMessage"),
        of: this.$t("main.common.table.text.of"),
        results: this.$t("main.common.table.text.results"),
        first: this.$t("main.common.table.text.first"),
        last: this.$t("main.common.table.text.last"),
      };
    },
  },
  watch: {
    search(value) {
      this.filters["search"] = value;
      this.filters = { ...this.filters };
    },
  },
});
</script>
