<template>
  <div class="home-view">
    <div class="home-view__container">
      <DeterminerDetailComponent></DeterminerDetailComponent>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import DeterminerDetailComponent from "@/determiners/components/DeterminerDetailComponent.vue";

export default defineComponent({
  name: "DeviceView",
  components: {
    DeterminerDetailComponent,
  },
});
</script>
