import axios from "@/helpers/axios";
import { IPermissionsRepository } from "../../ports/iPermissionsRepository";
import { Permissions } from "../../domain/models/permissions";

export class PermissionsApiAdpter implements IPermissionsRepository {
  async getPermissions(id: any, isMobile: boolean): Promise<Permissions[]> {
    const rol = id
      ? `?rol_id=${id}&is_mobil=${isMobile}`
      : `?is_mobil=${isMobile}`;
    const response = await axios.get(`api/settings-permissions/${rol}`);
    const data: any[] = response.data;
    return data.map((v) => new Permissions(v));
  }

  savePermissions(permissions: any): Promise<any> {
    return axios.post("api/settings-permissions/", permissions);
  }
}
