<template>
  <div class="contacts-module__container">
    <div class="contacts-module__container__header">
      <ul class="breadcrumbs">
        <li class="breadcrumbs__item">
          <a class="breadcrumbs__link">{{ $t("main.agents.agents") }}</a>
        </li>
      </ul>
    </div>
    <div class="contacts-module__container__component">
      <ListAgents></ListAgents>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import ListAgents from "../agents/components/ListAgents.vue";

export default defineComponent({
  name: "AgentsView",
  components: {
    ListAgents,
  },
});
</script>
