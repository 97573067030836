export default {
  permissions: {
    permissions: "Permissions",
    permissionsWeb: "Permissions web",
    PermissionsMobile: "Permissions mobile",
    toasts: {
      success_permissions: "Permissions added correctly",
    },
  },
};
