import { Determiner } from "./determiner";
import { IDeterminerRepository } from "../ports/iDeterminerRepository";

export class AddDeterminerUseCase {
  constructor(private determinerRepository: IDeterminerRepository) {}

  async execute(data: any): Promise<Determiner> {
    const dataToSend = { ...data };

    if (dataToSend["status_id"] && dataToSend["status_id"].length > 0) {
      dataToSend["status_id"] = dataToSend["status_id"][0]["id"];
    } else {
      delete dataToSend["status_id"];
    }

    if (dataToSend["user_id"] && dataToSend["user_id"].length > 0) {
      dataToSend["user_id"] = dataToSend["user_id"][0]["id"];
    } else {
      delete dataToSend["user_id"];
    }
    if (
      dataToSend["determiner_type_id"] &&
      dataToSend["determiner_type_id"].length > 0
    ) {
      dataToSend["determiner_type_id"] =
        dataToSend["determiner_type_id"][0]["id"];
    } else {
      delete dataToSend["determiner_type_id"];
    }

    return this.determinerRepository.saveDeterminer(dataToSend);
  }
}
