import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contacts-module__container" }
const _hoisted_2 = { class: "contacts-module__container__header" }
const _hoisted_3 = { class: "breadcrumbs" }
const _hoisted_4 = { class: "breadcrumbs__item" }
const _hoisted_5 = { class: "breadcrumbs__link" }
const _hoisted_6 = { class: "contacts-module__container__component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListDeterminers = _resolveComponent("ListDeterminers")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t("main.determiners.determinants")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_ListDeterminers)
    ])
  ]))
}