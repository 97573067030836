import { unLoadStyle } from "koffee-senser-components";
import Cookies from "js-cookie";

export default {
  getHTTPError(response: any) {
    const url = document.URL.toString();
    if (response?.status === 401 && !url.includes("/login")) {
      unLoadStyle();
      localStorage.removeItem("suite_admin_publicIp");
      Cookies.remove("suite_admin_refresh");
      localStorage.removeItem("suite_admin_permissions");
      Cookies.remove("suite_admin_access");
      Cookies.remove("suite_admin_styles");
      location.href = "/login";
    }
  },
  getErrorMessage(
    err: any,
    defaultErrorMessage?: string,
    t: any = (v: any) => v
  ) {
    const errors: string[] = [];
    if (err.response && err.response.status === 400) {
      if (typeof err.response.data.error !== "string") {
        for (const key in err.response.data.error) {
          const error = err.response.data.error[key];
          errors.push(t(error));
        }
      } else {
        errors.push(t(err.response.data));
      }
    } else {
      if (defaultErrorMessage) {
        errors.push(t(defaultErrorMessage));
      } else {
        errors.push(t("anUnexpectedERrrorOccurred"));
      }
    }
    return errors;
  },
};
