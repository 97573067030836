import axios from "axios";
import Cookies from "js-cookie";
import cookie from "@/helpers/cookies";
import HTTPResponseError from "@/helpers/HTTPResponseError";
import { setToast } from "koffee-senser-components";

const base_URL = process.env.VUE_APP_URL_BASE;

const koffeeSenserAPI = axios.create({
  baseURL: base_URL,
});

koffeeSenserAPI.interceptors.request.use((config: any) => {
  const ip: string | null = cookie.getStorage("publicIp");

  if (config.headers !== undefined) {
    const token = Cookies.get("suite_admin_access");
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["PublicIp"] = ip;
    config.headers["OriginTest"] = process.env?.VUE_APP_URL_ORIGIN;
  }
  return config;
});

koffeeSenserAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const isOnline = navigator.onLine;
    if (!isOnline) {
      setToast({
        type: "error",
        title: "Error process!",
        text: "No internet connection",
        size: "sm",
        close: true,
      });
    }
    HTTPResponseError.getHTTPError(error?.response);
    return Promise.reject(error);
  }
);
export default koffeeSenserAPI;
