export default {
  users: {
    determinatorUnassignedSuccessfully: "Device unassigned successfully.",
    customers: "Customers",
    addCustomer: "Add customer",
    editCustomer: "Edit customer",
    identification: "Identification",
    enterIdentification: "Enter your identification",
    names: "Names",
    enterNames: "Enter your names",
    lastNames: "Last names",
    enterLastNames: "Enter your last names",
    email: "Email",
    toast: {
      device: "Device",
      detalles_factura: "Invoice details",
      date: "Date: ",
      invoice_path: "File: ",
      number: "Invoice number: ",
      observation: "Observation: ",
      value: "Value: ",
      warranly_start: "Warranly start: ",
      warranly_end: "Warranly end",
    },
    enterEmail: "Enter your email",
    address: "Address",
    enterAddress: "Enter your address",
    title: "Error",
    text: "There is no file on this invoice",
    phone: "Phone",
    phones: "Phone (optional)",
    enterPhone: "Enter your phone",
    typeOfIdentification: "Type of identification",
    selectTypeOfIdentification: "Select type of identification",
    identificationNumber: "Identification number",
    role: "Role",
    selectRole: "Select the role",
    status: "Status",
    selectStatus: "Select the status",
    country: "Country",
    department: "Department",
    city: "City",
    created_by: "Creator",
    created_in: "Creation date",
    modified_by: "Editor",
    is_active: "State",
    modified_in: "Edition date",
    table: {
      identification: "Identification",
      full_name: "Full name",
      email: "Email",
      created_in: "Registration date",
      phone: "Phone",
      actions: "Actions",
      country_name: "Country",
      state_name: "Department",
      city_name: "City",
      rol_name: "Role",
      country_department_city: "Location",
      is_active: "State",
      viewCustomer: "View customer",
      active: "Active",
      inactive: "Inactive",
    },
    customerSuccessfullyCreated: "Customer successfully created",
    determinatorCorrectlyAssigned: "Device correctly assigned",
    changePassword: "Change password",
    password: "Password",
    validations: {
      already_identification_user:
        "A user with that identification already exists",
      the_verify_id_already_exists: "The verify id already exists",
      already_email_user: "A user with that email already exists",
    },
  },
  usersDetails: {
    basicData: "Basic data",
    areYouSureUnassignDevice: "Are you sure you want to unassign this device?",
    downloadFile: "Do you want to download the file?",
  },
};
