import { Determiner } from "./determiner";
import { IDeterminerRepository } from "../ports/iDeterminerRepository";

export class UpdateDeterminerUseCase {
  constructor(private determinerRepository: IDeterminerRepository) {}

  async execute(data: any): Promise<Determiner> {
    const dataToSend = { ...data };
    dataToSend["status_id"] = dataToSend["status_id"][0]["id"];
    dataToSend["determiner_type_id"] =
      dataToSend["determiner_type_id"][0]["id"];
    return this.determinerRepository.updateDeterminer(dataToSend);
  }
}
