import { User } from "../models/user";
import { IUserRepository } from "../../ports/iUserRepository";
import { IPaginatedResponse } from "../../../interfaces/IPaginatedResponse";

export class ListUsersUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(
    restApiUrl: any,
    axiosHeader: any
  ): Promise<IPaginatedResponse<User>> {
    return this.userRepository.getUsers(restApiUrl, axiosHeader);
  }
}
