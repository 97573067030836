import { IAgentsRepository } from "@/agents/ports/iAgentsRepository";
import { Agents } from "../models/agents";

export class AddAgentsUseCase {
  constructor(private agentsRepository: IAgentsRepository) {}

  async execute(data: any): Promise<Agents> {
    const dataToSend = { ...data };
    dataToSend["rol_id"] = dataToSend["rol_id"][0]["id"];
    dataToSend["user_status"] = dataToSend["user_status"][0]["id"];
    dataToSend["identification_type_id"] =
      dataToSend["identification_type_id"][0]["id"];
    return this.agentsRepository.saveAgents(dataToSend);
  }
}
