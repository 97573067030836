import { IPermissionsRepository } from "@/permissions/ports/iPermissionsRepository";

export class AddPermissionsUseCase {
  constructor(private permissionsRepository: IPermissionsRepository) {}

  async execute(data: any): Promise<any> {
    const dataToSend: any = { ...data };
    dataToSend["rol_id"] = dataToSend.rol_id[0].id;
    return this.permissionsRepository.savePermissions(dataToSend);
  }
}
