// src/interfaces/IRolData.ts
export interface IRolData {
  id: string;
  name: string;
  code_id: string;
}

export class Rol {
  id: string;
  name: string;
  code_id: string;

  constructor(data: IRolData | any) {
    this.id = data.id;
    this.name = data.name;
    this.code_id = data.code_id;
  }
}
