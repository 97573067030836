<template>
  <div class="home-view">
    <div class="home-view__container">
      <UserDetailComponent></UserDetailComponent>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import UserDetailComponent from "../users/components/UserDetailComponent.vue";

export default defineComponent({
  name: "UsersView",
  components: {
    UserDetailComponent,
  },
});
</script>
