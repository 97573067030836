export default {
  notes: {
    notes: "Notes",
    note: "Note",
    addNote: "Add note",
    editNote: "Edit note",
    enterTitleNote: "Enter the title of the note.",
    status: "Status",
    writeContentHere: "Write your content here...",
  },
};
