// src/interfaces/IDDeterminerData.ts
export interface IDeterminerData {
  id: string;
  code_id: string;
  verify_id: string;
  serial: string;
  model: string;
  track_number: string;
  send_date: string;
  estimate_delivery_date: string;
  delivery_date: string;
  status_id: string;
  user_id: string | null;
  determiner_type_id: string;
  type_name: string;
  state_name: string;
  user_name: string;
  is_active: string;
  owner: string;
  created_in: string;
}

export class Determiner {
  id: string;
  code_id: string;
  verify_id: string;
  serial: string;
  model: string;
  track_number: string;
  send_date: Date;
  estimate_delivery_date: Date;
  delivery_date: Date;
  status_id: string;
  user_id: string | null;
  determiner_type_id: string;
  is_active: string;
  created_in: string;

  constructor(determinerData: IDeterminerData | any) {
    this.id = determinerData.id;
    this.code_id = determinerData.code_id;
    this.verify_id = determinerData.verify_id;
    this.serial = determinerData.serial;
    this.model = determinerData.model;
    this.track_number = determinerData.track_number;
    this.send_date = determinerData.send_date;
    this.estimate_delivery_date = determinerData.estimate_delivery_date;
    this.delivery_date = determinerData.delivery_date;
    this.status_id = determinerData.status_id;
    this.user_id =
      determinerData.user_id !== null ? determinerData.user_id : null;
    this.determiner_type_id = determinerData.determiner_type_id;
    this.is_active = determinerData.is_active;
    this.created_in = determinerData.created_in;
  }
}
