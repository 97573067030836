import axios from "@/helpers/axios";
import { Note } from "@/note/domain/models/note";
import { INoteRepository } from "@/note/ports/iNoteRepository";

export class NoteApiAdapter implements INoteRepository {
  async getNotes(id: string): Promise<Note[]> {
    const response = await axios.get(
      `api/determinerNote/list_by_determiner/${id}/`
    );
    const data: any[] = response.data;
    return data.map((v) => new Note(v));
  }

  async saveNote(note: any): Promise<Note> {
    return axios.post("api/determinerNote/", note);
  }

  async updateNote(note: any): Promise<Note> {
    return axios.put(`api/determinerNote/${note.id}/`, note);
  }
}
