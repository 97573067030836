<template>
  <div class="contacts-module__container">
    <div class="contacts-module__container__header">
      <ul class="breadcrumbs">
        <li class="breadcrumbs__item">
          <a class="breadcrumbs__link">{{
            $t("main.permissions.permissions")
          }}</a>
        </li>
      </ul>
    </div>
    <div class="contacts-module__container__component">
      <div class="contact-info__container list_user-content">
        <div class="contact-info__content">
          <div class="sub-component__main-container">
            <!--Component container-->
            <!--Left content-->
            <div class="sub-component__left-container-web">
              <div class="headres-content">
                <span
                  class="activities-and-comments__header__title-container__title sub-component__item__header font-l-bold"
                  >{{ $t("main.permissions.permissionsWeb") }}</span
                >
              </div>
              <ListPermissions></ListPermissions>
            </div>
            <div class="sub-component__right-container">
              <div class="headres-content">
                <span
                  class="activities-and-comments__header__title-container__title sub-component__item__header font-l-bold"
                  >{{ $t("main.permissions.PermissionsMobile") }}</span
                >
              </div>
              <ListPermissions :is-mobile="true"></ListPermissions>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import ListPermissions from "../permissions/components/ListPermissions.vue";

export default defineComponent({
  name: "PermissionsView",
  components: {
    ListPermissions,
  },
});
</script>
