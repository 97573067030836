<template>
  <co-modal
    class="add-edit-user-modal"
    v-model:value="modalActive"
    size="md"
    :width="500"
    @close="close"
    dataTestId="invite_user_modal"
  >
    <template #title>
      <h3 class="add-edit-determiner-modal__title">
        {{ modalTitle }}
      </h3>
    </template>
    <template #body>
      <form
        class="add-edit-determiner-modal__form"
        autocomplete="off"
        onsubmit="return false;"
      >
        <div class="row_input_adsing">
          <co-select
            v-model:value="form.determinador"
            :placeholder="$t('main.determiners.enterDeterminant')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.determiners.determinant')"
            :items="determinadores"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.determinador"
          />
          <co-input-date-picker
            v-model:value="form.date"
            type="search"
            :label="$t('main.assign.date')"
            :placeholder="$t('main.assign.enterDate')"
            size="sm"
            block
            :required="true"
            :error="errors.date"
          />
        </div>
        <div class="row_input">
          <div class="row_input">$</div>
          <co-input
            v-model="form.value"
            type="text"
            :label="$t('main.assign.value')"
            :placeholder="$t('main.assign.enterValue')"
            size="md"
            :required="true"
            block
            :error="errors.value"
            @input="updateValue"
          />
          <co-input
            v-model="form.number"
            type="text"
            :label="$t('main.assign.number')"
            :placeholder="$t('main.assign.enterNumber')"
            size="md"
            block
            :required="true"
            :error="errors.number"
            @input="transformtoNotString"
          />
        </div>
        <div class="row_input">
          <co-input
            v-model="form.observation"
            type="text"
            :label="$t('main.assign.observation')"
            :placeholder="$t('main.assign.enterObservation')"
            size="md"
            block
            :error="errors.observation"
            @input="transformToLowerCase"
          />
        </div>
        <h4>
          {{ $t("main.assign.file") }}
        </h4>
        <div class="row_input">
          <co-upload-files
            :label="$t('main.assign.file')"
            :t="$t"
            :multiple="false"
            :extensionSupported="extensionSupported"
            type="file"
            size="md"
            block
            @selectedFiles="selectedFiles"
          />
        </div>
      </form>
    </template>
    <template #footer>
      <co-button size="sm" type="cancel" @trigger-click="close">
        <span>{{ $t("main.common.cancel") }}</span>
        <em class="mdi mdi-close"></em>
      </co-button>
      <co-button
        size="sm"
        type="primary"
        @trigger-click="save"
        :disabled="isSaving"
      >
        <span>{{ $t("main.common.save") }}</span>
        <img
          class="add-edit-user-modal__icon-save"
          :src="ICONS.MAIL"
          alt="Email"
        />
      </co-button>
    </template>
  </co-modal>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonModal } from "@/stores/modals";
import { ListDeterminerUnasignedUseCase } from "../domain/usecase/listDeterminerUnasignedUseCase";
import { AssingDeterminatorUserUseCase } from "../domain/usecase/assingDeterminatorUserUseCase";
import { UserApiAdapter } from "../adapters/api/userApiAdapter";
import { ICONS } from "koffee-senser-components";
import { IDeterminerData } from "@/determiners/domain/determiner";
import type { UploadFiles } from "koffee-senser-components/src/interfaces/upload-files.interface";
interface Determinadors extends IDeterminerData {
  id: string;
  name: string;
  code_id: string;
  verify_id: string;
  serial: string;
  model: string;
  track_number: string;
  is_active: string;
  type_name: string;
}
export default defineComponent({
  name: "AddOrEditUserModal",
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, ICONS };
  },
  props: {
    user_id: {
      type: String,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    assignmentData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      determinadores: [],
      status: [],
      unsigned: [],
      form: {
        determinador: "" as string,
        date: "" as string,
        value: "" as string,
        observation: "" as string,
        number: "" as string,
        invoice_path: null as File | null,
      },
      errors: {
        determinador: false,
        date: false,
        value: false,
        observation: false,
        number: false,
      },
      isSaving: false,
      isLoading: false,
      extensionSupported: [
        "image/png",
        "image/tiff",
        "image/bmp",
        "image/svg+xml",
        "image/jpeg",
        "image/jpeg",
        "application/pdf",
      ],
    };
  },
  mounted() {
    this.getDataList();
    if (this.isEditing && this.assignmentData) {
      this.preFillForm();
    }
  },
  watch: {
    "form.determinador": {
      deep: true,
      handler() {
        this.onDeterminadorChange();
      },
    },
  },
  computed: {
    modalTitle() {
      return this.isEditing
        ? this.$t("main.determiners.editDeterminer")
        : this.$t("main.determiners.assignDeterminer");
    },
    modalActive: {
      get(): boolean {
        return this.modalStore.modals.showAdsingDeterminerUserModal;
      },
      set(v: boolean) {
        this.modalStore.changeMultiModalState(
          "showAdsingDeterminerUserModal",
          v
        );
      },
    },
  },
  methods: {
    updateValue(event: any) {
      let inputValue = event.target.value;
      inputValue = inputValue.replace(/\D/g, "");
      let value = parseInt(inputValue);
      if (!isNaN(value)) {
        const formattedValue = value.toLocaleString("es-CO");
        this.form.value = formattedValue;
      }
    },
    transformToLowerCase() {
      this.form.observation = this.form.observation.toLowerCase();
    },
    transformtoNotString() {
      let inputValue = this.form.number;
      inputValue = inputValue.replace(/\D/g, "");
      this.form.number = inputValue;
    },
    selectedFiles(files: Array<UploadFiles>) {
      if (files.length > 0) {
        this.form.invoice_path = files[0].file;
      }
    },
    preFillForm() {
      const assignment = this.assignmentData;
      this.form.determinador = assignment.determinador;
      this.form.date = assignment.date;
      this.form.value = assignment.value;
      this.form.observation = assignment.observation;
      this.form.number = assignment.number;
    },
    getDataList() {
      const userRepository = new UserApiAdapter();
      const listDeterminerUnasignedUseCase = new ListDeterminerUnasignedUseCase(
        userRepository
      );
      listDeterminerUnasignedUseCase.execute().then((v: any) => {
        this.determinadores = v;
        this.unsigned = v;
      });
    },
    onDeterminadorChange() {
      const selectedDeterminador = this.form.determinador;
      if (
        selectedDeterminador &&
        selectedDeterminador.length > 0 &&
        typeof selectedDeterminador[0] === "object"
      ) {
        const selectedSerial = (selectedDeterminador[0] as Determinadors)
          .code_id;
        const determinadoresArray = this.unsigned as Determinadors[];
        const matchingDeterminador = determinadoresArray.find(
          (determinador) => determinador.code_id === selectedSerial
        );
        if (matchingDeterminador) {
          this.showDeterminadorDetailsAlert(matchingDeterminador);
        } else {
          this.showDeterminadorNotFoundAlert();
        }
      }
    },
    showDeterminadorDetailsAlert(determinador: Determinadors) {
      this.toast({
        type: "info",
        title: `${this.$t("main.determiners.title")}`,
        text: `
          ${this.$t("main.determiners.code")} =   ${determinador.code_id}<br>
          ${this.$t("main.determiners.verify_id")} =   ${
          determinador.verify_id
        }<br>
          ${this.$t("main.determiners.serial")} =   ${determinador.serial}<br>
          ${this.$t("main.determiners.model")} =   ${determinador.model}<br>
          ${this.$t("main.determiners.type")} =   ${determinador.type_name}<br>
          ${this.$t("main.determiners.trackNumber")} =   ${
          determinador.track_number
        }
        `,
        size: "sm",
        position: "top-right",
      });
    },
    showDeterminadorNotFoundAlert() {
      this.toast({
        type: "error",
        title: "Determinador no encontrado",
        position: "top-right",
      });
    },
    isFormCompleted(): boolean {
      const isEmpty = (value: string | null) => !value;
      const isEmptyAny = (value: any | null) => !value;
      this.errors.determinador = isEmpty(this.form.determinador);
      this.errors.date = isEmpty(this.form.date);
      this.errors.value = isEmptyAny(this.form.value);
      this.errors.observation = false;
      this.errors.number = isEmpty(this.form.number);
      return !Object.values(this.errors).some((value) => value);
    },
    close() {
      this.modalStore.changeMultiModalState(
        "showAdsingDeterminerUserModal",
        false
      );
      this.errors.determinador = false;
      this.errors.date = false;
      this.errors.value = false;
      this.errors.observation = false;
      this.errors.number = false;
      this.form = {
        determinador: "" as string,
        date: "" as string,
        value: "" as string,
        observation: "" as string,
        number: "" as string,
        invoice_path: null as File | null,
      };
      this.isSaving = false;
    },
    save() {
      this.isSaving = true;
      const isFormCompleted = this.isFormCompleted();
      if (isFormCompleted) {
        const userRepository = new UserApiAdapter();
        const assingDeterminatorUserUseCase = new AssingDeterminatorUserUseCase(
          userRepository
        );
        const payload = {
          ...this.form,
          observation: this.form.observation || null,
          invoice_path: this.form.invoice_path || null,
        };
        assingDeterminatorUserUseCase
          .execute(this.form.determinador[0], this.user_id, payload)
          .then(() => {
            this.toast({
              type: "success",
              title: this.$t("main.common.toasts.success_title"),
              text: this.$t("main.users.determinatorCorrectlyAssigned"),
              size: "sm",
            });
            this.isSaving = false;
            this.$emit("onSave");
            this.close();
            this.getDataList();
          })
          .catch((e) => {
            console.error(e);
            this.isSaving = false;
          });
      } else {
        this.toast({
          type: "error",
          title: this.$t("main.common.toasts.error_title"),
          text: this.$t("main.common.toasts.missingDataFilledIn"),
          size: "sm",
        });
        this.isSaving = false;
      }
    },
  },
});
</script>
