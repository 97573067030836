<template>
  <div class="contact-info__container list_user-content">
    <!--Component container-->
    <div class="contact-info__header">
      <div class="contact-info__header__path" @click="back">
        <img :src="ICONS.LEFT_ARROW" alt="back-icon" />
        <span class="font-s">{{ $t("main.common.back") }}</span>
      </div>
      <div class="contact-info__header--left">
        <div class="contact-info__header__avatar-container" v-if="user">
          <co-avatar size="md" type="primary" data-testid="business_name">
            <span v-if="user.first_name" class="">{{
              (user?.first_name ?? "")[0].toUpperCase()
            }}</span>
          </co-avatar>
          <span class="contact-name font-l-bold">
            {{
              (user?.identification ?? "") +
              " - " +
              (user?.first_name ?? "") +
              "  " +
              (user?.last_name ?? "")
            }}
          </span>
        </div>
        <co-tooltip :label="$t('main.common.edit')" position="right">
          <co-button type="icon" @click="editContactInfo">
            <img :src="ICONS.EDIT" alt="icon-edit" />
          </co-button>
        </co-tooltip>
      </div>
      <div class="contact-info__header--right"></div>
    </div>
    <div class="contact-info__content">
      <div class="sub-component__main-container">
        <!--Component container-->
        <!--Left content-->
        <div class="sub-component__left-container">
          <div class="contact-info__container">
            <div class="contact-info__container__basic-data-container">
              <div class="basic-data">
                <div class="sub-component__item">
                  <div class="sub-component__item__header">
                    <span class="font-l-bold">{{
                      $t("main.usersDetails.basicData")
                    }}</span>
                  </div>
                  <div class="sub-component__item__body">
                    <co-loading v-if="isLoadingBasicData" specific></co-loading>

                    <div v-if="!isLoadingBasicData && user">
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.identification") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.identification
                              ? user.identification
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.names") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.first_name
                              ? user.first_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.lastNames") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.last_name
                              ? user.last_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.phone") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <div
                            class="basic-data__exists__content__item-container__value__phones-container"
                          >
                            <div
                              class="basic-data__exists__content__item-container__value__phones-container__phone-container"
                            >
                              <span>
                                <span v-if="user.phone"
                                  >{{ user.phone.country.sufix }}
                                  {{ user.phone.phone }}</span
                                >
                                <span v-else>
                                  {{ $t("main.common.notRegistered") }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.phones") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <div
                            class="basic-data__exists__content__item-container__value__phones-container"
                          >
                            <div
                              class="basic-data__exists__content__item-container__value__phones-container__phone-container"
                            >
                              <span>
                                <span
                                  v-if="
                                    user.phone_2 && user.phone_2.phone != ''
                                  "
                                  >{{ user.phone_2.country.sufix }}
                                  {{ user.phone_2.phone }}</span
                                >
                                <span v-else>
                                  {{ $t("main.common.notRegistered") }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.email") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.email
                              ? user.email
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("Rol") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.rol_name
                              ? user.rol_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.is_active") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.is_active
                              ? user.is_active
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.country") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.country_name
                              ? user.country_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.department") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.state_name
                              ? user.state_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.city") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.city_name
                              ? user.city_name
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.address") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.address
                              ? user.address
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <!-- Nuevos campos -->
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.created_by") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.created_by
                              ? user.created_by
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.created_in") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span v-if="user && user.created_in">
                            {{
                              new FormatDate(
                                $t("main.common.language"),
                                user.created_in
                              ).getFullDate()
                            }}
                          </span>
                          <span v-else>
                            {{
                              user.created_in
                                ? user.created_in
                                : $t("main.common.notRegistered")
                            }}
                          </span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.modified_by") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span>{{
                            user.modified_by
                              ? user.modified_by
                              : $t("main.common.notRegistered")
                          }}</span>
                        </div>
                      </div>
                      <div class="basic-data__exists__content__item-container">
                        <div
                          class="basic-data__exists__content__item-container__key"
                        >
                          <span>{{ $t("main.users.modified_in") }}</span>
                        </div>
                        <div
                          class="basic-data__exists__content__item-container__value"
                        >
                          <span v-if="user && user.modified_in">
                            {{
                              new FormatDate(
                                $t("main.common.language"),
                                user.modified_in
                              ).getFullDate()
                            }}
                          </span>
                          <span v-else>
                            {{
                              user.modified_in
                                ? user.modified_in
                                : $t("main.common.notRegistered")
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="!isLoadingBasicData && !user"
                      class="no-data-container"
                    >
                      {{ $t("thereIsNoBasicDataContact") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Right content-->
        <div class="sub-component__right-container">
          <div class="headres-content">
            <span
              class="activities-and-comments__header__title-container__title sub-component__item__header font-l-bold"
              >{{ $t("main.determiners.determinants") }}</span
            >
            <co-button
              size="sm"
              type="primary"
              @trigger-click="addDeterminer"
              dataTestId="invite_user_button"
            >
              <span class="users-business__header__span">
                {{ $t("main.determiners.assignDeterminer") }}
              </span>
              <img
                class="list-contact__header__icon"
                :src="ICONS.PLUS"
                alt="icon_add"
              />
            </co-button>
          </div>
          <co-data-table
            :api="api"
            :filtros="filters"
            :headers="headersC"
            :updater="updater"
            :texts="texts"
            :promiseApi="listDeterminerApi"
          >
            <template #item-actions="item">
              <div class="users-business__table__actions" style="display: flex">
                <co-tooltip
                  class="Determiners-business__table__actions__view"
                  :label="$t('main.common.viewEdit')"
                  position="top"
                >
                  <co-button type="icon" @click="viewDetailDeterminer(item)">
                    <img
                      style="cursor: pointer"
                      alt="view-user"
                      :src="ICONS.EYE"
                      :isEditing="isEditing"
                      class="users-business__table__actions__icon"
                    />
                  </co-button>
                </co-tooltip>
                <co-tooltip
                  class="Determiners-business__table__actions__view"
                  :label="$t('main.common.viewDocument')"
                  position="top"
                >
                  <img
                    style="cursor: pointer; width: 90%"
                    alt="view-user"
                    :src="ICONS.DOWNLOAD_FILE"
                    class="users-business__table__actions__icon"
                    @click="openConfirmDownload(item.item)"
                  />
                </co-tooltip>
                <co-tooltip
                  class="Determiners-business__table__actions__view"
                  :label="$t('main.common.viewUnAsigned')"
                  position="top"
                >
                  <img
                    style="cursor: pointer; width: 90%"
                    alt="view-user"
                    :src="ICONS.DELETE"
                    class="users-business__table__actions__icon"
                    @click="openConfirmDelete(item.item)"
                  />
                </co-tooltip>
              </div>
            </template>
          </co-data-table>
        </div>
      </div>
    </div>
    <AddOrEditUserModal
      dataTestId="invite_user_modal"
      :isEdit="isEdit"
      @onSave="() => getUserData()"
      ref="refuser"
    />
    <AdsingDeterminerUserComponent :user_id="id" @onSave="() => updater++" />
    <co-confirm
      v-model:value="modalStore.modals.showConfirmUnasignedDeterminer"
      @modalClosed="
        modalStore.changeMultiModalState(
          'showConfirmUnasignedDeterminer',
          false
        )
      "
      :t="$t"
      @modalConfirm="unAsignedDeterminer"
    >
      <div v-html="$t('main.usersDetails.areYouSureUnassignDevice')"></div>
    </co-confirm>
    <co-confirm
      v-model:value="modalStore.modals.showConfirmDowloadDeterminer"
      @modalClosed="
        modalStore.changeMultiModalState('showConfirmDowloadDeterminer', false)
      "
      :t="$t"
      @modalConfirm="download"
    >
      <div v-html="$t('main.usersDetails.downloadFile')"></div>
    </co-confirm>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { FormatDate, ICONS } from "koffee-senser-components";
import router from "@/router";
import AddOrEditUserModal from "./AddOrEditUserModal.vue";
import AdsingDeterminerUserComponent from "./AdsingDeterminerUserComponent.vue";
import { useCommonModal } from "@/stores/modals";
import { GetUserInfoUseCase } from "../domain/usecase/getUserInfoUseCase";
import { UnasignedDeviceUseCase } from "../domain/usecase/unasignedDeviceUseCase";
import { UserApiAdapter } from "../adapters/api/userApiAdapter";
import { ListDeterminersUseCase } from "../../determiners/domain/listDeterminersUseCase";
import { DeterminerApiAdapter } from "../../determiners/adapters/api/determinerApiAdapter";
import type {
  Filters,
  Header,
  DatatableText,
} from "koffee-senser-components/src/interfaces/data-table.interface";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "UserDetail",
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    const route = useRoute();
    return { modalStore, toast, can, FormatDate, ICONS, route };
  },
  components: {
    AddOrEditUserModal,
    AdsingDeterminerUserComponent,
  },
  data() {
    return {
      user: null as any,
      api: "/api/determiner/determiners_by_user/",
      isLoadingBasicData: true,
      headers: [
        { text: "Codigo", value: "device.code_id" },
        { text: "Serial", value: "device.serial" },
        { text: "Modelo", value: "device.model" },
        { text: "Acciones", value: "actions" },
      ] as Header[],
      updater: 0,
      filters: {} as Filters,
      isEdit: false,
      isEditing: false,
      fieldToDelete: null as any,
      id: "",
    };
  },
  mounted() {
    this.id = this.route.params.id as string;
    this.api = this.api + this.id + "/";
    this.getUserData();
  },
  computed: {
    headersC(): any[] {
      if (!this.headers) {
        return [];
      }
      return this.headers.map((v) => {
        const name = this.$t("main.determiners.table." + v.value);
        return {
          ...v,
          text: "isAditional" in v ? v.text : name,
        };
      });
    },
    texts(): DatatableText {
      return {
        rowsPerpage: this.$t("main.common.table.text.rowsPerpage"),
        emptyMessage: this.$t("main.common.table.text.emptyMessage"),
        of: this.$t("main.common.table.text.of"),
        results: this.$t("main.common.table.text.results"),
        first: this.$t("main.common.table.text.first"),
        last: this.$t("main.common.table.text.last"),
      };
    },
  },
  methods: {
    viewDetailDeterminer(item: any) {
      const r = item.item;
      // formatear
      const d2 = r.date;
      const d4 = r.warranly_start;
      const d5 = r.warranly_end;
      const d12 = new FormatDate(
        this.$t("main.common.language"),
        d2
      ).getFullDateString();
      const d14 = new FormatDate(
        this.$t("main.common.language"),
        d4
      ).getFullDateString();
      const d15 = new FormatDate(
        this.$t("main.common.language"),
        d5
      ).getFullDateString();

      const toastMessage = `
        ${this.$t("main.users.toast.device")}: ${r.device.serial}<br/>
        ${this.$t("main.users.toast.date")}: ${d12}<br/>
        ${this.$t("main.users.toast.value")}: ${r.value}<br/>
        ${this.$t("main.users.toast.number")}: ${r.number}<br/>
        ${this.$t("main.users.toast.warranly_start")}: ${d14}<br/>
        ${this.$t("main.users.toast.warranly_end")}: ${d15}<br/>
        ${this.$t("main.users.toast.observation")}: ${r.observation}<br/>
      `;
      this.toast({
        type: "info",
        title: this.$t("main.users.toast.detalles_factura"),
        text: toastMessage,
        size: "sm",
      });
    },
    back() {
      router.push("/app/users");
    },
    openConfirmDelete(item: any) {
      this.fieldToDelete = item;
      this.modalStore.changeMultiModalState(
        "showConfirmUnasignedDeterminer",
        true
      );
    },
    openConfirmDownload(item: any) {
      this.fieldToDelete = item;
      this.modalStore.changeMultiModalState(
        "showConfirmDowloadDeterminer",
        true
      );
    },
    unAsignedDeterminer() {
      const userRepository = new UserApiAdapter();
      const unasignedUeCase = new UnasignedDeviceUseCase(userRepository);
      unasignedUeCase.execute(this.fieldToDelete.id).then((v: boolean) => {
        if (v) {
          this.updater++;
          this.toast({
            type: "success",
            title: this.$t("main.common.toasts.success_title"),
            text: this.$t("main.users.determinatorUnassignedSuccessfully"),
            size: "sm",
          });
          this.getUserData();
          this.modalStore.changeMultiModalState(
            "showConfirmUnasignedDeterminer",
            false
          );
        } else {
          this.toast({
            type: "error",
            title: this.$t("error"),
            text: this.$t("error al desasinar"),
            size: "sm",
          });
        }
      });
    },
    addDeterminer() {
      this.modalStore.changeMultiModalState(
        "showAdsingDeterminerUserModal",
        true
      );
    },
    editContactInfo() {
      this.modalStore.changeMultiModalState("showEditOrCreateUserModal", true);
      this.isEdit = true;
      (this.$refs["refuser"] as any).setUser(this.user);
    },
    getUserData() {
      const userRepository = new UserApiAdapter();
      const getUserInfoUseCase = new GetUserInfoUseCase(userRepository);
      this.isLoadingBasicData = true;
      getUserInfoUseCase.executeBy(this.id).then((v: any) => {
        this.user = v.data;
        this.isLoadingBasicData = false;
      });
    },
    listDeterminerApi(restApiUrl: any, axiosHeader: any) {
      const determinerRepository = new DeterminerApiAdapter();
      const listDeterminersUseCase = new ListDeterminersUseCase(
        determinerRepository
      );
      return listDeterminersUseCase.execute(restApiUrl, axiosHeader);
    },
    download() {
      const userRepository = new UserApiAdapter();
      userRepository
        .downloadFile(this.fieldToDelete.id)
        .then((fileBlob) => {
          const contentType = fileBlob.type;
          const extension = contentType.split("/")[1];
          const url = window.URL.createObjectURL(fileBlob);
          const link = document.createElement("a");
          link.href = url;

          let fileName = "archivo";
          if (contentType.includes("pdf")) {
            fileName += ".pdf";
          } else if (contentType.includes("png")) {
            fileName += ".png";
          } else {
            fileName += "." + extension;
          }

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);

          this.modalStore.changeMultiModalState(
            "showConfirmDowloadDeterminer",
            false
          );
        })
        .catch(() => {
          this.toast({
            type: "error",
            title: this.$t("main.users.title"),
            text: this.$t("main.users.text"),
            size: "sm",
          });
          this.modalStore.changeMultiModalState(
            "showConfirmDowloadDeterminer",
            false
          );
        });
    },
  },
});
</script>
