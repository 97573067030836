<template>
  <div data-testid="list_permission">
    <form autocomplete="off" onsubmit="return false;">
      <div class="row_input_permissions">
        <co-select
          v-model:value="form.rol_id"
          :placeholder="$t('main.users.selectRole')"
          :placeholder-search="$t('components.web.common.search')"
          :label="$t('main.users.role')"
          :items="rols"
          itemText="name"
          position="bottom"
          time
          size="sm"
          :required="true"
        />
        <co-button
          size="sm"
          type="primary"
          :disabled="disabled"
          @trigger-click="save"
        >
          {{ $t("main.common.save") }}
          <img
            class="invite-note-modal__icon-save"
            :src="ICONS.MAIL"
            alt="Email"
          />
        </co-button>
      </div>
    </form>
    <co-loading v-if="isLoading" specific />
    <div v-for="item in modules" :key="item.id">
      <co-accordion
        v-model:open="item.open"
        :icon="ICONS.BUSINESS"
        :label="item.name"
        :disabled="disabled"
      >
        <div v-for="(action, index) in item.components" :key="index">
          <co-accordionTable
            :name="action.name"
            :code="action.id"
            v-model:checked="action.is_check"
            :disabled="disabled"
          >
          </co-accordionTable>
        </div>
      </co-accordion>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { ICONS } from "koffee-senser-components";
import { PermissionsApiAdpter } from "../adapters/api/permissionsApiAdapter";
import { ListPermissionsUseCase } from "../domain/usecase/listPermissionsUseCase";
import { Permissions } from "../domain/models/permissions";
import { ListRolsUseCase } from "../../users/domain/usecase/listRolsUseCase";
import { Rol } from "../../users/domain/models/rol";
import { UserApiAdapter } from "../../users/adapters/api/userApiAdapter";
import { AddPermissionsUseCase } from "../domain/usecase/addPermissionsUseCase";
export default defineComponent({
  name: "ListPermissions",
  setup() {
    const toast: any = inject("toast");
    return { ICONS, toast };
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabled: true,
      disabledMobie: true,
      indice: 0,
      rols: [] as Rol[],
      id: null,
      modules: [] as Permissions[],
      isSaving: false,
      isLoading: false,
      form: {
        rol_id: null as any,
        components: [] as string[],
      },
    };
  },
  mounted() {
    this.getData();
    const userRepository = new UserApiAdapter();
    const listRolsUseCase = new ListRolsUseCase(userRepository);
    listRolsUseCase.execute(!this.isMobile).then((v: any) => {
      this.rols = v;
    });
  },
  watch: {
    "form.rol_id": {
      deep: true,
      handler() {
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      this.modules = [];
      this.isLoading = true;
      const permissionsRepository = new PermissionsApiAdpter();
      const listPermissionsUseCase = new ListPermissionsUseCase(
        permissionsRepository
      );
      let idRol = null;
      if (this.form.rol_id) {
        this.disabled = false;
        idRol = this.form.rol_id[0].id;
      }
      listPermissionsUseCase
        .execute(idRol, this.isMobile)
        .then((v: any) => {
          this.modules = v;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    save() {
      this.isSaving = true;
      this.modules.forEach((m) => {
        const component: string[] = m.components
          .filter((o) => o.is_check == true)
          .map((c) => c.id);
        component.forEach((c) => {
          if (!this.form.components.includes(c)) {
            this.form.components.push(c);
          }
        });
      });
      const permissionsRepository = new PermissionsApiAdpter();
      let usecase = new AddPermissionsUseCase(permissionsRepository);
      usecase
        .execute(this.form)
        .then(() => {
          const successMessage = this.$t(
            "main.permissions.toasts.success_permissions"
          );
          this.toast({
            type: "success",
            title: this.$t("main.common.toasts.success_title"),
            text: successMessage,
            size: "sm",
          });
          this.isSaving = false;
          this.getData();
        })
        .catch(() => {
          const errorMessage = this.$t("main.common.toasts.error_title");
          this.toast({
            type: "error",
            title: errorMessage,
            text: errorMessage || this.$t("main.common.toasts.unknown_error"),
            size: "sm",
          });
          this.isSaving = false;
        });
    },
  },
});
</script>
