<template>
  <div>
    <div class="note" v-for="item in sortedNotes" :key="item.determiner_id">
      <div class="note__header">{{ item.name }}</div>
      <div class="note__content" v-html="item.note"></div>
      <div class="note__info">
        <div>{{ $t("main.determiners.created_bys") + "&nbsp;" }}</div>
        <div v-html="item.created_by + '&nbsp;'"></div>
        <div>{{ $t("main.determiners.on") + "&nbsp;" }}</div>
        <span v-if="item && item.modified_in">
          {{
            new FormatDate(
              $t("main.common.language"),
              item.modified_in
            ).getFullDateTimeString()
          }}
        </span>
      </div>
      <div class="extra content">
        <co-button type="icon" @trigger-click="() => editNoteInfo(item)">
          <img :src="ICONS.EDIT" alt="" />
        </co-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { NoteApiAdapter } from "../adapters/api/noteApiAdapter";
import { Note } from "../domain/models/note";
import { ListNotesUseCase } from "../domain/usecase/listNotesUseCase";
import { FormatDate, ICONS } from "koffee-senser-components";

export default defineComponent({
  name: "ListNote",
  props: {
    determiner_id: {
      type: String,
      default: "",
    },
  },
  setup() {
    return { ICONS, FormatDate };
  },
  data() {
    return {
      isEdit: false,
      notes: [] as Note[],
    };
  },
  computed: {
    sortedNotes() {
      // Ordenar las notas por fecha de modificación de más reciente a más antigua
      return this.notes.slice().sort((a, b) => {
        const dateA = new Date(a.modified_in) as any;
        const dateB = new Date(b.modified_in) as any;
        return dateB - dateA;
      });
    },
  },
  methods: {
    getData() {
      const noteRepository = new NoteApiAdapter();
      const listNotesUseCase = new ListNotesUseCase(noteRepository);
      listNotesUseCase.execute(this.determiner_id).then((v: any) => {
        this.notes = v;
      });
    },
    editNoteInfo(note: any) {
      this.$emit("editNoteInfo", note);
    },
  },
  mounted() {
    this.getData();
  },
});
</script>
