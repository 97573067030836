<template>
  <co-modal
    class="add-edit-user-modal"
    v-model:value="modalActive"
    size="md"
    :width="680"
    @close="close"
    dataTestId="invite_user_modal"
  >
    <template #title>
      <h3 class="add-edit-user-modal__title">
        {{
          isEdit ? $t("main.users.editCustomer") : $t("main.users.addCustomer")
        }}
      </h3>
    </template>
    <template #body>
      <form
        class="add-edit-user-modal__form"
        autocomplete="off"
        onsubmit="return false;"
      >
        <div class="row_input">
          <co-input
            type="text"
            v-model="form.first_name"
            :label="$t('main.users.names')"
            :placeholder="$t('main.users.enterNames')"
            size="md"
            block
            :required="true"
            :error="errors.first_name"
            @input="transformToUpper('first_name')"
          />
          <co-input
            type="text"
            v-model="form.last_name"
            :label="$t('main.users.lastNames')"
            :placeholder="$t('main.users.enterLastNames')"
            size="md"
            block
            :required="true"
            :error="errors.last_name"
            @input="transformToUpper('last_name')"
          />
        </div>
        <div>
          <co-input
            type="text"
            v-model="form.email"
            :label="$t('main.users.email')"
            :placeholder="$t('main.users.enterEmail')"
            size="md"
            block
            :required="true"
            :error="errors.email"
          />
          <co-input
            type="text"
            v-model="form.address"
            :label="$t('main.users.address')"
            :placeholder="$t('main.users.enterAddress')"
            size="md"
            block
            :required="true"
            :error="errors.address"
          />
        </div>
        <div class="row_input">
          <co-input-phone
            type="text"
            v-model:value="form.phone"
            :label="$t('main.users.phone')"
            :placeholder="$t('main.users.enterPhone')"
            size="md"
            block
            :required="true"
            :error="errors.phone"
          />
          <co-input-phone
            type="text"
            v-model:value="form.phone_2"
            :label="$t('main.users.phones')"
            :placeholder="$t('main.users.enterPhone')"
            size="md"
            block
          />
        </div>
        <div class="row_input">
          <co-select
            v-model:value="form.identification_type_id"
            :placeholder="$t('main.users.selectTypeOfIdentification')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.typeOfIdentification')"
            :items="identificationsType"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.identification_type_id"
          />
          <co-input
            type="text"
            v-model="form.identification"
            :label="$t('main.users.identificationNumber')"
            :placeholder="$t('main.users.identificationNumber')"
            size="md"
            block
            :required="true"
            :error="errors.identification"
            @input="transformtoNotString"
          />
        </div>
        <div class="row_input">
          <co-select
            v-model:value="form.rol_id"
            :placeholder="$t('main.users.selectRole')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.role')"
            :items="rols"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.rol_id"
          />
          <co-select
            v-model:value="form.user_status"
            :placeholder="$t('main.users.selectStatus')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.status')"
            :items="status"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.user_status"
          />
        </div>
        <div class="row_input">
          <co-select
            v-model:value="country"
            @update:value="changeCountry"
            :placeholder="$t('main.users.country')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.country')"
            :items="countries"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.country"
          />
          <co-select
            v-model:value="state"
            @update:value="changeState"
            :placeholder="$t('main.users.department')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.department')"
            :items="departments"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.departament"
          />
          <co-select
            v-model:value="form.city"
            :placeholder="$t('main.users.city')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.city')"
            :items="cities"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.city"
          />
        </div>
        <div class="row_input" v-if="isEdit">
          <co-switch
            v-model:value="changePassword"
            :label="$t('main.users.changePassword')"
            size="sm"
            block
          />
          <co-input
            v-if="changePassword"
            type="password"
            v-model="form.password"
            :label="$t('main.users.password')"
            :placeholder="$t('main.users.password')"
            size="md"
            block
            :required="true"
            :error="errors.password"
          />
        </div>
      </form>
    </template>
    <template #footer>
      <co-button size="sm" type="cancel" @trigger-click="close">
        {{ $t("main.common.cancel") }}
        <em class="mdi mdi-close"></em>
      </co-button>
      <co-button
        size="sm"
        type="primary"
        @trigger-click="save"
        :disabled="isSaving"
      >
        {{ $t("main.common.save") }}
        <img
          class="add-edit-user-modal__icon-save"
          :src="ICONS.MAIL"
          alt="Email"
        />
      </co-button>
    </template>
  </co-modal>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonModal } from "@/stores/modals";
import { ListRolsUseCase } from "../domain/usecase/listRolsUseCase";
import { ListIdentificationTypeUseCase } from "../domain/usecase/listIdentificationType";
import { ListStatussUseCase } from "../domain/usecase/listStatusUseCase";
import { ListCountriesUseCase } from "../domain/usecase/listCountriesUseCase";
import { ListDepartmentsUseCase } from "../domain/usecase/listDepartmentsUseCase";
import { ListCitiesUseCase } from "../domain/usecase/listCitiesUseCase";
import { AddUserUseCase } from "../domain/usecase/addUserUseCase";
import { UserApiAdapter } from "../adapters/api/userApiAdapter";
import { validations } from "koffee-senser-components";
import { UpdateUserUseCase } from "../domain/usecase/updateUserUseCase";
import { Country } from "../domain/models/country";
import { Rol } from "../domain/models/rol";
import { Status } from "../domain/models/status";
import { Department } from "../domain/models/department";
import { City } from "../domain/models/city";
import { FormClient, IPhone } from "../domain/models/formClient";
import { ICONS } from "koffee-senser-components";
// import type { IFormClientData } from "../domain/models/formClient";

import type { AxiosError } from "axios";

export default defineComponent({
  name: "AddOrEditUserModal",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, ICONS };
  },
  data() {
    return {
      changePassword: false,
      rols: [] as Rol[],
      identificationsType: [],
      status: [] as Status[],
      countries: [] as Country[],
      cities: [] as City[],
      departments: [] as Department[],
      country: "" as any,
      state: "" as any,
      form: new FormClient({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null as IPhone | null,
        phone_2: null as IPhone | null,
        rol_id: null,
        identification_type_id: null,
        identification: null,
        user_status: null,
        city: null,
        address: null,
        password: null as any,
      }),
      errors: {
        first_name: false,
        last_name: false,
        phone: false,
        phone_2: false,
        email: false,
        rol_id: false,
        identification_type_id: false,
        identification: false,
        user_status: false,
        city: false,
        country: false,
        departament: false,
        password: false,
        address: false,
      },
      isSaving: false,
      isLoading: false,
    };
  },
  mounted() {
    const userRepository = new UserApiAdapter();
    const listRolsUseCase = new ListRolsUseCase(userRepository);
    const listStatussUseCase = new ListStatussUseCase(userRepository);
    const listCountries = new ListCountriesUseCase(userRepository);
    const listIdentificationTypeUseCase = new ListIdentificationTypeUseCase(
      userRepository
    );
    listCountries.execute().then((v) => {
      this.countries = v;
    });
    listRolsUseCase.execute(false).then((v: any) => {
      this.rols = v;
    });
    listIdentificationTypeUseCase.execute().then((v: any) => {
      this.identificationsType = v;
    });
    listStatussUseCase.execute().then((v: any) => {
      this.status = v;
    });
  },
  computed: {
    modalActive: {
      get(): boolean {
        return this.modalStore.modals.showEditOrCreateUserModal;
      },
      set(v: boolean) {
        this.modalStore.changeMultiModalState("showEditOrCreateUserModal", v);
      },
    },
  },
  methods: {
    transformToUpper(field: keyof FormClient) {
      let value = this.form[field] as string;
      const words = value.split(" ");
      const capitalizedWords = words.map((word) => {
        const trimmedWord = word.replace(/[^a-zA-Z\s]/g, "").toLowerCase();
        return trimmedWord.charAt(0).toUpperCase() + trimmedWord.slice(1);
      });
      const capitalizedValue = capitalizedWords.join(" ");
      this.form[field] = capitalizedValue;
    },
    transformtoNotString() {
      let inputValue = this.form.identification;
      inputValue = inputValue.replace(/\D/g, "");
      this.form.identification = inputValue;
    },
    async changeCountry() {
      const userRepository = new UserApiAdapter();
      const listDepartmentsUseCase = new ListDepartmentsUseCase(userRepository);
      if (this.country && this.country.length > 0) {
        await listDepartmentsUseCase
          .execute(this.country[0]["id"])
          .then((v) => {
            this.departments = v;
          });
      }
    },
    async changeState() {
      const userRepository = new UserApiAdapter();
      const listCitiesUseCase = new ListCitiesUseCase(userRepository);
      if (this.state && this.state.length > 0) {
        await listCitiesUseCase.execute(this.state[0]["id"]).then((v) => {
          this.cities = v;
        });
      }
    },
    async setUser(user: any) {
      const data = { ...user };
      const city = data["city"];
      const state = data["state"];
      const country = data["country"];
      this.form = new FormClient({ ...data });
      this.form.rol_id = this.rols.find((v: any) => v.id == data["rol_id"]);
      this.form.user_status = this.status.find(
        (v: any) => v.id == data["user_status"]
      );
      this.form.identification_type_id = this.identificationsType.find(
        (v: any) => v.id == data["identification_type_id"]
      );
      if (country && state && city) {
        this.country = [this.countries.find((v: any) => v.id == country)];
        await this.changeCountry();
        this.state = [this.departments.find((v: any) => v.id == state)];
        await this.changeState();
        this.form.city = this.cities.find((v: any) => v.id == city);
      }
    },
    isFormCompleted(): boolean {
      // Funciones de validación individuales
      const isEmpty = (value: string | null) => !value;
      const isInvalidEmail = (email: string | null) =>
        !email || !validations.email.test(email);

      // Actualizar el objeto de errores
      this.errors.first_name = isEmpty(this.form.first_name);
      this.errors.last_name = isEmpty(this.form.last_name);
      this.errors.email = isInvalidEmail(this.form.email);
      this.errors.rol_id = isEmpty(this.form.rol_id);
      this.errors.user_status = isEmpty(this.form.user_status);
      this.errors.identification_type_id = isEmpty(
        this.form.identification_type_id
      );
      this.errors.address = isEmpty(this.form.address);
      this.errors.phone_2 = false;
      this.errors.city = !this.form.city;
      this.errors.country = isEmpty(this.country);
      this.errors.departament = isEmpty(this.state);
      this.errors.identification = isEmpty(this.form.identification);

      if (this.changePassword) {
        this.errors.password = isEmpty(this.form.password);
      } else {
        this.errors.password = false;
      }

      this.errors.phone =
        !this.form.phone || !this.form.phone.country || !this.form.phone.phone;

      // Comprobar si hay errores
      return !Object.values(this.errors).some((value) => value);
    },
    close() {
      this.modalStore.changeMultiModalState("showEditOrCreateUserModal", false);
      this.errors.first_name = false;
      this.errors.last_name = false;
      this.errors.phone = false;
      this.errors.phone_2 = false;
      this.errors.email = false;
      this.errors.rol_id = false;
      this.errors.user_status = false;
      this.errors.identification_type_id = false;
      this.errors.city = false;
      this.errors.departament = false;
      this.errors.country = false;
      this.errors.password = false;
      this.errors.address = false;
      this.country = "" as any;
      this.state = "" as any;
      this.changePassword = false;
      this.form = new FormClient({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null as IPhone | null,
        phone_2: null as IPhone | null,
        rol_id: null,
        identification_type_id: null,
        identification: null,
        user_status: null,
        city: null,
        password: null,
        address: null,
      });
      this.isSaving = false;
    },
    save() {
      this.isSaving = true;
      const isFormCompleted = this.isFormCompleted();
      if (isFormCompleted) {
        const userRepository = new UserApiAdapter();
        let usecase = this.isEdit
          ? new UpdateUserUseCase(userRepository)
          : new AddUserUseCase(userRepository);
        usecase
          .execute(this.form)
          .then(() => {
            const successMessage = this.isEdit
              ? this.$t("main.users.customerSuccessfullyUpdated")
              : this.$t("main.users.customerSuccessfullyCreated");

            this.toast({
              type: "success",
              title: this.$t("main.common.toasts.success_title"),
              text: successMessage,
              size: "sm",
            });
            this.isSaving = false;
            this.$emit("onSave");
            this.close();
          })
          .catch((r: AxiosError<Array<string>, any>) => {
            const errorTitleMessage = this.isEdit
              ? this.$t("main.common.toasts.error_edit_title")
              : this.$t("main.common.toasts.error_title");
            let errorMessage = "";
            if (r.response?.status == 400) {
              errorMessage = r.response?.data
                .map((v) => this.$t(v))
                .join("<br>");
            } else {
              errorMessage = this.$t("main.common.toasts.unknown_error");
            }

            this.toast({
              type: "error",
              title: errorTitleMessage,
              text: errorMessage,
              size: "sm",
            });

            this.isSaving = false;
          });
      } else {
        this.toast({
          type: "error",
          title: this.$t("main.common.toasts.error_title"),
          text: this.$t("main.common.toasts.missingDataFilledIn"),
          size: "sm",
        });
        this.isSaving = false;
      }
    },
  },
});
</script>
