// src/interfaces/ICountryData.ts
export interface ICountryData {
  id: string;
  name: string;
  code_id: string;
}

export class Country {
  id: string;
  name: string;
  code_id: string;

  constructor(data: ICountryData | any) {
    this.id = data.id;
    this.name = data.name;
    this.code_id = data.code_id;
  }
}
