<template>
  <co-modal
    class="add-edit-determiner-modal"
    v-model:value="modalActive"
    size="md"
    :width="600"
    @close="close"
    dataTestId="invite_determiner_modal"
  >
    <template #title>
      <h1 class="add-edit-user-modal__title">
        {{
          isEdit
            ? $t("main.determiners.editCustomer")
            : $t("main.determiners.addCustomer")
        }}
      </h1>
    </template>
    <template #body>
      <form
        class="add-edit-determiner-modal__form"
        autocomplete="off"
        @submit.prevent="save"
      >
        <form class="form-row">
          <co-input
            type="text"
            v-model="form.code_id"
            :label="$t('main.determiners.code')"
            :placeholder="$t('main.determiners.enterCode')"
            size="md"
            block
            :required="true"
            :error="errors.code_id"
            dataTestId="determiner_code_id"
            class="custom-width"
          />
          <co-input
            type="text"
            v-model="form.verify_id"
            :label="$t('main.determiners.verify_id')"
            :placeholder="$t('main.determiners.enterverify_id')"
            size="md"
            block
            :required="true"
            :error="errors.verify_id"
            dataTestId="determiner_verify_id"
            class="custom-width"
          />
        </form>
        <form class="form-row">
          <co-input
            type="text"
            v-model="form.serial"
            :label="$t('main.determiners.serial')"
            :placeholder="$t('main.determiners.enterSerial')"
            size="md"
            block
            :required="true"
            :error="errors.serial"
            dataTestId="determiner_serial"
            class="custom-width"
          />
          <co-input
            type="text"
            v-model="form.model"
            :label="$t('main.determiners.model')"
            :placeholder="$t('main.determiners.enterModel')"
            size="md"
            block
            :required="true"
            :error="errors.model"
            dataTestId="determiner_model"
            class="custom-width"
          />
        </form>
        <form class="form-row">
          <co-input
            type="text"
            v-model="form.track_number"
            :label="$t('main.determiners.trackNumber')"
            :placeholder="$t('main.determiners.enterTrackNumber')"
            size="md"
            block
            :error="errors.track_number"
            dataTestId="determiner_track_number"
            class="custom-width"
          />
          <co-select
            v-model:value="form.determiner_type_id"
            :placeholder="$t('main.determiners.selectType')"
            :label="$t('main.determiners.type')"
            :placeholder-search="$t('components.web.common.search')"
            :items="type"
            itemText="name"
            position="bottom"
            time
            :required="true"
            :error="errors.determiner_type_id"
            class="custom-width"
          />
        </form>
        <form class="form-row">
          <co-select
            v-model:value="form.status_id"
            :placeholder="$t('main.determiners.selectStatus')"
            :label="$t('main.determiners.status')"
            :placeholder-search="$t('components.web.common.search')"
            :items="status"
            itemText="name"
            position="bottom"
            time
            :required="true"
            :error="errors.status_id"
            class="custom-width"
          />
        </form>
      </form>
    </template>
    <template #footer>
      <co-button size="sm" type="cancel" @trigger-click="close">
        {{ $t("main.common.cancel") }}
        <em class="mdi mdi-close"></em>
      </co-button>
      <co-button
        size="sm"
        type="primary"
        @trigger-click="save"
        :disabled="isSaving"
      >
        {{ $t("main.common.save") }}
        <img
          class="add-edit-determiner-modal__icon-save"
          :src="ICONS.MAIL"
          alt="Email"
        />
      </co-button>
    </template>
  </co-modal>
</template>
<style scoped>
.form-row {
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.custom-width {
  width: 100%;
  margin-right: 10px;
}
</style>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonModal } from "@/stores/modals";
import { ListTypesUseCase } from "../domain/listTypeUseCase";
import { ListStatusDeterminerUseCase } from "../domain/listStatusUseCase";
import { AddDeterminerUseCase } from "../domain/addDeterminerUseCase";
import { DeterminerApiAdapter } from "../adapters/api/determinerApiAdapter";
import { Status } from "../domain/status";
import { UpdateDeterminerUseCase } from "../domain/updateDeterminerUseCase";
import { Type } from "../domain/type";
import { Determiner } from "../domain/determiner";
import { ICONS } from "koffee-senser-components";
// import { error } from "console";
import type { AxiosError } from "axios";
export default defineComponent({
  name: "AddOrEditDeterminerComponent",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, ICONS };
  },
  data() {
    return {
      type: [] as Type[],
      status: [] as Status[],
      form: {
        code_id: "" as string,
        verify_id: "" as string,
        serial: "" as string,
        model: "" as string,
        track_number: "" as string,
        status_id: "" as any,
        determiner_type_id: "" as any,
      },
      errors: {
        code_id: false,
        verify_id: false,
        serial: false,
        model: false,
        status_id: false,
        determiner_type_id: false,
      },
      isSaving: false,
      isLoading: false,
    };
  },
  mounted() {
    const determinerRepository = new DeterminerApiAdapter();
    const listTypeUseCase = new ListTypesUseCase(determinerRepository);
    const listStatusUseCase = new ListStatusDeterminerUseCase(
      determinerRepository
    );
    listTypeUseCase.execute().then((v: any) => {
      this.type = v;
    });
    listStatusUseCase.execute().then((v: any) => {
      this.status = v;
    });
  },
  computed: {
    modalActive: {
      get(): boolean {
        return this.modalStore.modals.showEditOrCreateDeterminerModal;
      },
      set(v: boolean) {
        this.modalStore.changeMultiModalState(
          "showEditOrCreateDeterminerModal",
          v
        );
      },
    },
  },
  methods: {
    async setDeterminer(determiner: any) {
      const data = { ...determiner };
      this.form = new Determiner({ ...data });
      this.form.status_id = this.status.find(
        (v: any) => v.id == data["status_id"]
      );
      this.form.determiner_type_id = this.type.find(
        (v: any) => v.id == data["determiner_type_id"]
      );
    },
    isFormCompleted(): boolean {
      this.errors.code_id = this.form.code_id === "";
      this.errors.verify_id = this.form.verify_id === "";
      this.errors.serial = this.form.serial === "";
      this.errors.model = this.form.model === "";
      this.errors.status_id =
        this.form.status_id === "" || this.form.status_id === null;
      // Commented out the user validation
      this.errors.determiner_type_id =
        this.form.determiner_type_id === "" ||
        this.form.determiner_type_id === null;
      return !Object.values(this.errors).some((error) => error);
    },

    close() {
      this.modalStore.changeMultiModalState(
        "showEditOrCreateDeterminerModal",
        false
      );
      this.errors.code_id = false;
      this.errors.verify_id = false;
      this.errors.serial = false;
      this.errors.model = false;
      this.errors.status_id = false;
      this.errors.determiner_type_id = false;
      this.form = {
        code_id: "" as string,
        verify_id: "" as string,
        serial: "" as string,
        model: "" as string,
        track_number: "" as string,
        status_id: "" as string,
        determiner_type_id: "" as string,
      };
      this.isSaving = false;
    },
    save() {
      this.isSaving = true;
      const isFormCompleted = this.isFormCompleted();

      if (isFormCompleted && this.form.verify_id.startsWith("0")) {
        this.toast({
          type: "error",
          title: this.$t("main.common.toasts.error_title"),
          text: this.$t("main.determiners.varify_id_v"),
          size: "sm",
        });
        this.isSaving = false;
        return;
      }

      if (isFormCompleted) {
        const determinerRepository = new DeterminerApiAdapter();
        let usecase = this.isEdit
          ? new UpdateDeterminerUseCase(determinerRepository)
          : new AddDeterminerUseCase(determinerRepository);

        usecase
          .execute(this.form)
          .then(() => {
            const successMessage = this.isEdit
              ? this.$t("main.determiners.determinatorSuccessfullyUpdated")
              : this.$t("main.determiners.determinatorSuccessfullyCreated");

            this.toast({
              type: "success",
              title: this.$t("main.common.toasts.success_title"),
              text: successMessage,
              size: "sm",
            });
            this.isSaving = false;
            this.close();
            this.$emit("onSave");
          })
          .catch((error: AxiosError<Array<string>, any>) => {
            const errorMessage = this.isEdit
              ? this.$t("main.common.toasts.error_edit_title")
              : this.$t("main.common.toasts.error_title");
            let errorText = this.$t("main.common.toasts.unknown_error");
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data
            ) {
              if (Array.isArray(error.response.data)) {
                errorText = error.response.data
                  .map((item) => this.$t(item))
                  .join("<br>");
              } else if (typeof error.response.data === "object") {
                errorText = Object.values(error.response.data).join("<br>");
              } else {
                errorText = error.response.data;
              }
            }
            this.toast({
              type: "error",
              title: errorMessage,
              text: errorText,
              size: "sm",
              html: true,
            });

            this.isSaving = false;
          });
      } else {
        this.toast({
          type: "error",
          title: this.$t("main.common.toasts.error_title"),
          text: this.$t("main.common.toasts.missingDataFilledIn"),
          size: "sm",
        });
        this.isSaving = false;
      }
    },
  },
});
</script>
