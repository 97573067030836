// src/interfaces/IUserData.ts
export interface IUserData {
  id: string;
  username: string;
  password: string; // Considerar la necesidad de esta propiedad
  rol_id: string;
  identification_type_id: string;
  identification: string;
  email: string;
  date_joined: string; // Fecha como string para luego convertirla
  picture: string | null;
  phone: string; // JSON string
  phone_2: string; // JSON string
  user_status: string;
}

export class User {
  id: string;
  username: string;
  password: string; // Considerar no incluir si no es necesario para la lógica del cliente
  rolId: string;
  identificationTypeId: string;
  identification: string;
  email: string;
  dateJoined: Date;
  picture: string | null;
  phone: {
    mobile: string;
    home: string;
  };
  phone_2: {
    mobile: string;
    home: string;
  };
  userStatus: string;
  is_active: boolean;

  constructor(userData: IUserData | any) {
    this.id = userData.id;
    this.username = userData.username;
    this.password = userData.password; // Considerar la necesidad de esta propiedad
    this.rolId = userData.rol_id;
    this.identificationTypeId = userData.identification_type_id;
    this.identification = userData.identification;
    this.email = userData.email;
    this.dateJoined = new Date(userData.date_joined);
    this.picture = userData.picture;
    this.phone = JSON.parse(userData.phone);
    this.phone_2 = JSON.parse(userData.phone_2);
    this.userStatus = userData.user_status;
    this.is_active = userData.is_active;
  }
}
