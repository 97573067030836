export default {
  determiners: {
    varify_id_v: "The verify id field data cannot start at 0",
    customers: "Customers",
    addCustomer: "Add device",
    editCustomer: "Edit device",
    determinants: "Device",
    addDeterminer: "Add device",
    code: "Code",
    enterCode: "Enter the code",
    deliverydate: "Delivery date",
    enterdeliverydate: "Enter delivery date",
    verify_id: "Verify id",
    enterverify_id: "Enter the verify id",
    serial: "Serial",
    enterSerial: "Enter the serial",
    model: "Model",
    enterModel: "Enter the model",
    trackNumber: "Track number",
    enterTrackNumber: "Enter the track number",
    estimate_delivery_date: "Estimated delivery date",
    enterestimate_delivery_date: "Enter estimated delivery date",
    send_date: "Send date",
    entersend_date: "Enter send date",
    type: "Type",
    selectType: "Select type",
    status: "Status",
    selectStatus: "Select status",
    user: "User",
    selectUser: "Select user",
    assignDeterminer: "Assign device",
    editDeterminer: "Edit device",
    determinant: "Device",
    enterDeterminant: "Enter the device",
    is_active: "Is active",
    enteris_active: "Enter is active",
    owner: "Owner",
    enterowner: "Enter owner",
    on: "On",
    created_bys: "Created by: ",
    created_by: "Creator",
    created_in: "Creation date",
    modified_by: "Editor",
    modified_in: "Edition date",
    name: "Name",
    entername: "Enter name",
    title: "Detail device",
    table: {
      device: {
        code_id: "Code",
        verify_id: "Verify id",
        serial: "Serial",
        model: "Model",
        is_active: "Is active",
        track_number: "Track number",
        send_date: "Send date",
        delivery_date: "Delivery date",
        state_name: "Status",
        owner: "Owner",
        created_in: "Creation date",
        type_name: "Type",
        estimate_delivery_date: "Estimate delivery date",
        actions: "Action",
      },
      code_id: "Code",
      verify_id: "Verify id",
      serial: "Serial",
      model: "Model",
      is_active: "Is active",
      track_number: "Track number",
      send_date: "Send date",
      delivery_date: "Delivery date",
      state_name: "Status",
      owner: "Owner",
      type_name: "Type",
      estimate_delivery_date: "Estimate delivery date",
      actions: "Action",
    },
    validations: {
      the_code_id_already_exists:
        "A determiner with that code id already exists",
      the_verify_id_already_exists:
        "The determiner this that verify id already exists",
    },
    determinatorSuccessfullyCreated: "Device successfully created",
    determinatorSuccessfullyUpdated: "Device successfully modified",
  },
  determinerDetails: {
    basicData: "Device data",
  },
  assign: {
    date: "Date of invoice",
    enterDate: "Enter is date",
    value: "Value",
    enterValue: "Enter the value",
    observation: "Observation",
    enterObservation: "Enter is observation",
    number: "Number of invoice",
    enterNumber: "Enter the number",
    file: "File",
  },
  determinerImport: {
    importDevices: "Import",
    import: "Import",
    download: "Download",
    here: "Here",
    attachFile: "Attach file",
    infoImport:
      "the base format to include the devices. if you already have a format continue with the process.",
    thisFileDoesNotConformFormat: "This file does not conform format",
    noFileSelected: "No file selected",
    anUnexpectedErrorOccurred: "An unexpected error occurred",
    fileWasImportedButSomeRecordsFailed:
      "File was imported but some records failed",
    yourProductsHasBeenImportedCorrectly:
      "Your products has been imported correctly",
    theFileWasUploadedSuccessfully: "The file was uploaded successfully",
    seeMore: "See more",
  },
};
