import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "note__header" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "note__info" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "extra content" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_co_button = _resolveComponent("co-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedNotes, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "note",
        key: item.determiner_id
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(item.name), 1),
        _createElementVNode("div", {
          class: "note__content",
          innerHTML: item.note
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t("main.determiners.created_bys") + " "), 1),
          _createElementVNode("div", {
            innerHTML: item.created_by + ' '
          }, null, 8, _hoisted_4),
          _createElementVNode("div", null, _toDisplayString(_ctx.$t("main.determiners.on") + " "), 1),
          (item && item.modified_in)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(new _ctx.FormatDate(
              _ctx.$t("main.common.language"),
              item.modified_in
            ).getFullDateTimeString()), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_co_button, {
            type: "icon",
            onTriggerClick: () => _ctx.editNoteInfo(item)
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.ICONS.EDIT,
                alt: ""
              }, null, 8, _hoisted_7)
            ]),
            _: 2
          }, 1032, ["onTriggerClick"])
        ])
      ]))
    }), 128))
  ]))
}