import { Type } from "./type";
import { IDeterminerRepository } from "../ports/iDeterminerRepository";

export class ListTypesUseCase {
  constructor(private determinerRepository: IDeterminerRepository) {}

  async execute(): Promise<Type[]> {
    return this.determinerRepository.getType();
  }
}
