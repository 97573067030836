import { IPaginatedResponse } from "@/interfaces/IPaginatedResponse";
import { IAgentsRepository } from "../ports/iAgentsRepository";
import { Agents } from "../domain/models/agents";
import axios from "@/helpers/axios";

export class AgentsApiAdapter implements IAgentsRepository {
  async getAgents(
    url: string,
    adicionalHeader: Record<string, string>
  ): Promise<IPaginatedResponse<Agents>> {
    return axios.get(url, {
      headers: {
        ...adicionalHeader,
      },
    });
  }

  async saveAgents(agents: any): Promise<Agents> {
    return axios.post(`/api/agents/`, agents);
  }

  async updateAgents(agents: any): Promise<Agents> {
    return axios.put(`/api/agents/${agents.id}/`, agents);
  }
}
