export default {
  determiners: {
    varify_id_v: "El dato del campo id verificación no puede iniciar en 0",
    customers: "Dispositivos",
    addCustomer: "Agregar dispositivo",
    editCustomer: "Editar dispositivo",
    determinants: "Dispositivos",
    addDeterminer: "Agregar dispositivo",
    code: "Código",
    enterCode: "Ingrese el código",
    deliverydate: "Fecha de entrega",
    enterdeliverydate: "Ingrese fecha de entrega",
    estimate_delivery_date: "Fecha estimada de entrega",
    enterestimate_delivery_date: "Ingrese la fecha estimada de entrega",
    send_date: "Fecha de envío",
    entersend_date: "Ingrese fecha de envío",
    verify_id: "Id verificación",
    enterverify_id: "Ingrese la id verificación",
    serial: "Serial",
    enterSerial: "Ingrese el serial",
    model: "Modelo",
    enterModel: "Ingrese el modelo",
    trackNumber: "Número de guía",
    enterTrackNumber: "Ingrese el número de guía",
    type: "Tipo",
    selectType: "Seleccione el tipo",
    status: "Estado",
    selectStatus: "Seleccione el estado",
    user: "Usuario",
    selectUser: "Seleccione el usuario",
    is_active: "Es activo",
    enteris_active: "Ingrese si es activo",
    assignDeterminer: "Asignar dispositivo",
    editDeterminer: "Editar dispositivo",
    determinant: "Dispositivo",
    enterDeterminant: "Seleccione el dispositivo",
    on: "El",
    owner: "Dueño",
    enterowner: "Ingrese dueño",
    created_bys: "Creado por: ",
    created_by: "Creador",
    created_in: "Fecha creacion",
    modified_by: "Editor",
    modified_in: "Fecha edicion",
    name: "Nombre",
    entername: "Ingrese nombre",
    title: "Detalle determinador",
    table: {
      device: {
        code_id: "Código",
        verify_id: " Id verificación",
        serial: "Serial",
        model: "Modelo",
        track_number: "Número de guía",
        send_date: "Fecha de envío",
        estimate_delivery_date: "Fecha estimada de entrega",
        delivery_date: "Fecha de entrega",
        is_active: "Es activo",
        state_name: "Estado",
        owner: "Dueño",
        type_name: "Tipo",
        actions: "Acción",
        created_in: "Fecha de creación",
      },
      code_id: "Código",
      verify_id: "Id verificacion",
      serial: "Serial",
      model: "Modelo",
      track_number: "Número de guía",
      send_date: "Fecha de envío",
      estimate_delivery_date: "Fecha estimada de entrega",
      delivery_date: "Fecha de entrega",
      is_active: "Es activo",
      state_name: "Estado",
      owner: "Dueño",
      type_name: "Tipo",
      actions: "Acción",
    },
    validations: {
      the_code_id_already_exists: "Ya existe un determinador con este codigo",
      the_verify_id_already_exists:
        "Ya existe un determinador con este id verificacion",
    },
    determinatorSuccessfullyCreated: "Dispositivo creado correctamente",
    determinatorSuccessfullyUpdated: "Dispositivo actualizado correctamente",
  },
  determinerDetails: {
    basicData: "Datos del dispositivo",
  },
  assign: {
    date: "Fecha de factura",
    enterDate: "Ingrese la fecha",
    value: "Valor",
    enterValue: "Ingrese el valor",
    observation: "Observación",
    enterObservation: "Ingrese la observación",
    number: "Número de factura",
    enterNumber: "Ingrese el número",
    file: "Archivo",
  },
  determinerImport: {
    importDevices: "Importación",
    import: "Importar",
    download: "Descargar",
    here: " Aquí ",
    attachFile: "Subir archivo",
    infoImport:
      "el formato base para incluir los dispositivos. ¡Si ya cuentas con un formato, continúa con el proceso.",
    thisFileDoesNotConformFormat: "Este archivo no se ajusta al formato",
    noFileSelected: "Archivo no seleccionado",
    anUnexpectedErrorOccurred: "Ocurrió un error inesperado",
    fileWasImportedButSomeRecordsFailed:
      "El archivo fue importado pero algunos registros fallaron",
    yourProductsHasBeenImportedCorrectly:
      "Sus dispositivos han sido importados correctamente",
    theFileWasUploadedSuccessfully: "El archivo fue cargado exitosamente",
    seeMore: "Ver más",
  },
};
