import { Status } from "../models/status";
import { IUserRepository } from "../../ports/iUserRepository";

export class ListStatussUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(): Promise<Status[]> {
    return this.userRepository.getStatus();
  }
}
