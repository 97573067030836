<template>
  <div class="contacts-module__container">
    <div class="contacts-module__container__header">
      <ul class="breadcrumbs">
        <li class="breadcrumbs__item">
          <a class="breadcrumbs__link">
            {{ $t("main.determiners.determinants") }}
          </a>
        </li>
      </ul>
    </div>
    <div class="contacts-module__container__component">
      <ListDeterminers></ListDeterminers>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import ListDeterminers from "../determiners/components/ListDeterminers.vue";

export default defineComponent({
  name: "DeterminerView",
  components: {
    ListDeterminers,
  },
});
</script>
