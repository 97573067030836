import cookies from "@/helpers/cookies";
import { setToast } from "koffee-senser-components";
import Cookies from "js-cookie";

export default {
  state() {
    return true;
    const isNewUser = JSON.parse(cookies.getStorage("permissions") || "");
    const isNewUserState = isNewUser?.user_status_code === "new_state";
    if (isNewUserState && !location.pathname.includes("/profile")) {
      const token = Cookies.get("suite_admin_access") || "";
      const tokenEncode = btoa(token);
      const url = `${process.env.VUE_APP_SUITE_URL}/login/redirect-token?token=${tokenEncode}`;
      location.href = url;
    }
    if (isNewUserState) {
      setToast({
        type: "warning",
        title: "Warning",
        text: "Please complete your profile",
        size: "sm",
      });
    }
  },
};
