import { Country } from "../models/country";
import { IUserRepository } from "../../ports/iUserRepository";

export class ListDepartmentsUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(id: string): Promise<Country[]> {
    return this.userRepository.getDeparmentsByCountri(id);
  }
}
