// src/interfaces/IDeterminerData.ts
export interface IDeterminerData {
  id: string;
  name: string;
  code_id: string;
  verify_id: string;
  serial: string;
  model: string;
  track_number: string;
  is_active: string;
  type_name: string;
  // owner: string;
}

export class Determiner {
  id: string;
  name: string;
  code_id: string;
  verify_id: string;
  serial: string;
  model: string;
  track_number: string;
  is_active: string;
  type_name: string;
  // owner: string;

  constructor(data: IDeterminerData | any) {
    this.id = data.id;
    this.name = data.name;
    this.code_id = data.code_id;
    this.verify_id = data.verify_id;
    this.serial = data.serial;
    this.model = data.model;
    this.track_number = data.track_number;
    this.is_active = data.is_active;
    this.type_name = data.type_name;
    // this.owner = data.owner;
  }
}
