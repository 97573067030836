<template>
  <co-modal
    class="add-edit-agents-modal"
    v-model:value="modalActive"
    size="md"
    :width="680"
    @close="close"
    dataTestId="invite_agents_modal"
  >
    <template #title>
      <h3 class="add-edit-agents-modal__title">
        {{ isEdit ? $t("main.agents.editAgent") : $t("main.agents.addAgent") }}
      </h3>
    </template>
    <template #body>
      <form
        class="add-edit-agents-modal__form"
        autocomplete="off"
        onsubmit="return false;"
      >
        <div class="row_input">
          <co-input
            type="text"
            v-model="form.first_name"
            :label="$t('main.users.names')"
            :placeholder="$t('main.users.enterNames')"
            size="md"
            block
            :required="true"
            :error="errors.first_name"
            @input="transformToUpper('first_name')"
          />
          <co-input
            type="text"
            v-model="form.last_name"
            :label="$t('main.users.lastNames')"
            :placeholder="$t('main.users.enterLastNames')"
            size="md"
            block
            :required="true"
            :error="errors.last_name"
            @input="transformToUpper('last_name')"
          />
        </div>
        <div class="row_input">
          <co-input
            type="text"
            v-model="form.email"
            :label="$t('main.users.email')"
            :placeholder="$t('main.users.enterEmail')"
            size="md"
            block
            :required="true"
            :error="errors.email"
          />
          <co-select
            v-model:value="form.identification_type_id"
            :placeholder="$t('main.users.selectTypeOfIdentification')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.typeOfIdentification')"
            :items="identificationsType"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.identification_type_id"
          />
        </div>
        <div class="row_input">
          <co-input
            type="text"
            v-model="form.identification"
            :label="$t('main.users.identificationNumber')"
            :placeholder="$t('main.users.identificationNumber')"
            size="md"
            block
            :required="true"
            :error="errors.identification"
            @input="transformtoNotString"
          />
          <co-select
            v-model:value="form.rol_id"
            :placeholder="$t('main.users.selectRole')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.role')"
            :items="rols"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.rol_id"
          />
        </div>
        <div class="row_input">
          <co-select
            v-model:value="form.user_status"
            :placeholder="$t('main.users.selectStatus')"
            :placeholder-search="$t('components.web.common.search')"
            :label="$t('main.users.status')"
            :items="status"
            itemText="name"
            position="bottom"
            time
            size="sm"
            :required="true"
            :error="errors.user_status"
          />
        </div>
        <div class="row_input" v-if="isEdit">
          <co-switch
            v-model:value="changePassword"
            :label="$t('main.users.changePassword')"
            size="sm"
            block
          />
          <co-input
            v-if="changePassword"
            type="password"
            v-model="form.password"
            :label="$t('main.users.password')"
            :placeholder="$t('main.users.password')"
            size="md"
            block
            :required="true"
            :error="errors.password"
          />
        </div>
      </form>
    </template>
    <template #footer>
      <co-button size="sm" type="cancel" @trigger-click="close">
        {{ $t("main.common.cancel") }}
        <em class="mdi mdi-close"></em>
      </co-button>
      <co-button
        size="sm"
        type="primary"
        @trigger-click="save"
        :disabled="isSaving"
      >
        {{ $t("main.common.save") }}
        <img
          class="add-edit-agents-modal__icon-save"
          :src="ICONS.MAIL"
          alt="Email"
        />
      </co-button>
    </template>
  </co-modal>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";
import { useCommonModal } from "@/stores/modals";
import { AgentsApiAdapter } from "../adapters/agentsApiAdapter";
import { validations } from "koffee-senser-components";
import { AddAgentsUseCase } from "../domain/usecase/addAgentsUseCase";
import { FormAgents } from "../domain/models/formAgents";
import { Rol } from "../domain/models/rol";
import { Status } from "../domain/models/status";
import { UpdateAgentsUseCase } from "../domain/usecase/updateAgentsUseCase";
import { ListRolsUseCase } from "../../users/domain/usecase/listRolsUseCase";
import { ListStatussUseCase } from "../../users/domain/usecase/listStatusUseCase";
import { ListIdentificationTypeUseCase } from "../../users/domain/usecase/listIdentificationType";
import { UserApiAdapter } from "../../users/adapters/api/userApiAdapter";
import { ICONS } from "koffee-senser-components";
import type { AxiosError } from "axios";

export default defineComponent({
  name: "AddOrEditAgentsModal",
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modalStore = useCommonModal();
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    return { modalStore, toast, can, ICONS };
  },
  data() {
    return {
      changePassword: false,
      rols: [] as Rol[],
      identificationsType: [],
      status: [] as Status[],
      country: "" as any,
      state: "" as any,
      form: new FormAgents({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        rol_id: null,
        identification_type_id: null,
        identification: null,
        user_status: null,
        password: null as any,
      }),
      errors: {
        first_name: false,
        last_name: false,
        email: false,
        rol_id: false,
        identification_type_id: false,
        identification: false,
        user_status: false,
        password: false,
      },
      isSaving: false,
      isLoading: false,
    };
  },
  mounted() {
    const userRepository = new UserApiAdapter();
    const listRolsUseCase = new ListRolsUseCase(userRepository);
    const listStatussUseCase = new ListStatussUseCase(userRepository);
    const listIdentificationTypeUseCase = new ListIdentificationTypeUseCase(
      userRepository
    );
    listRolsUseCase.execute(true).then((v: any) => {
      this.rols = v;
    });
    listIdentificationTypeUseCase.execute().then((v: any) => {
      this.identificationsType = v;
    });
    listStatussUseCase.execute().then((v: any) => {
      this.status = v;
    });
  },
  computed: {
    modalActive: {
      get(): boolean {
        return this.modalStore.modals.showEditOrCreateAgentsModal;
      },
      set(v: boolean) {
        this.modalStore.changeMultiModalState("showEditOrCreateAgentsModal", v);
      },
    },
  },
  methods: {
    transformToUpper(field: keyof FormAgents) {
      let value = this.form[field] as string;
      const words = value.split(" ");
      const capitalizedWords = words.map((word) => {
        const trimmedWord = word.replace(/[^a-zA-Z\s]/g, "").toLowerCase();
        return trimmedWord.charAt(0).toUpperCase() + trimmedWord.slice(1);
      });
      const capitalizedValue = capitalizedWords.join(" ");
      this.form[field] = capitalizedValue;
    },
    transformtoNotString() {
      let inputValue = this.form.identification;
      inputValue = inputValue.replace(/\D/g, "");
      this.form.identification = inputValue;
    },
    async setAgents(agents: any) {
      const data = { ...agents };
      this.form = new FormAgents({ ...data });
      this.form.rol_id = this.rols.find((v: any) => v.id == data["rol_id"]);
      this.form.user_status = this.status.find(
        (v: any) => v.id == data["user_status"]
      );
      this.form.identification_type_id = this.identificationsType.find(
        (v: any) => v.id == data["identification_type_id"]
      );
    },
    isFormCompleted(): boolean {
      const isEmpty = (value: string | null) => !value;
      const isInvalidEmail = (email: string | null) =>
        !email || !validations.email.test(email);
      this.errors.first_name = isEmpty(this.form.first_name);
      this.errors.last_name = isEmpty(this.form.last_name);
      this.errors.email = isInvalidEmail(this.form.email);
      this.errors.rol_id = isEmpty(this.form.rol_id);
      this.errors.user_status = isEmpty(this.form.user_status);
      this.errors.identification_type_id = isEmpty(
        this.form.identification_type_id
      );
      this.errors.identification = isEmpty(this.form.identification);

      if (this.changePassword) {
        this.errors.password = isEmpty(this.form.password);
      } else {
        this.errors.password = false;
      }
      return !Object.values(this.errors).some((value) => value);
    },
    close() {
      this.modalStore.changeMultiModalState(
        "showEditOrCreateAgentsModal",
        false
      );
      this.errors.first_name = false;
      this.errors.last_name = false;
      this.errors.email = false;
      this.errors.rol_id = false;
      this.errors.user_status = false;
      this.errors.identification_type_id = false;
      this.errors.password = false;
      this.country = "" as any;
      this.state = "" as any;
      this.changePassword = false;
      this.form = new FormAgents({
        id: null,
        first_name: null,
        last_name: null,
        email: null,
        rol_id: null,
        identification_type_id: null,
        identification: null,
        user_status: null,
        password: null,
      });
      this.isSaving = false;
      setTimeout(() => this.$emit("onClose"), 300);
    },
    save() {
      this.isSaving = true;
      const isFormCompleted = this.isFormCompleted();
      if (isFormCompleted) {
        const agentsRepository = new AgentsApiAdapter();
        let usecase = this.isEdit
          ? new UpdateAgentsUseCase(agentsRepository)
          : new AddAgentsUseCase(agentsRepository);
        usecase
          .execute(this.form)
          .then(() => {
            const successMessage = this.isEdit
              ? this.$t("main.agents.agentSuccessfullyUpdated")
              : this.$t("main.agents.agentSuccessfullyCreated");
            this.toast({
              type: "success",
              title: this.$t("main.common.toasts.success_title"),
              text: successMessage,
              size: "sm",
            });
            this.isSaving = false;
            this.$emit("onSave");
            this.close();
          })
          .catch((error: AxiosError<Array<string>, any>) => {
            const errorTitleMessage = this.isEdit
              ? this.$t("main.common.toasts.error_edit_title")
              : this.$t("main.common.toasts.error_title");
            let errorText = this.$t("main.common.toasts.unknown_error");
            if (
              error.response &&
              error.response.status === 400 &&
              error.response.data
            ) {
              if (Array.isArray(error.response.data)) {
                errorText = error.response.data
                  .map((item) => this.$t(item))
                  .join("<br>");
              } else if (typeof error.response.data === "object") {
                errorText = Object.values(error.response.data).join("<br>");
              } else {
                errorText = error.response.data;
              }
            }
            this.toast({
              type: "error",
              title: errorTitleMessage,
              text: errorText,
              size: "sm",
            });
            this.isSaving = false;
          });
      } else {
        this.toast({
          type: "error",
          title: this.$t("main.common.toasts.error_title"),
          text: this.$t("main.common.toasts.missingDataFilledIn"),
          size: "sm",
        });
        this.isSaving = false;
      }
    },
  },
});
</script>
