// src/interfaces/IFormClientData.ts

export interface IPhone {
  country: any | null;
  phone: string | null;
}

export interface IFormClientData {
  id: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: IPhone | null;
  phone_2: IPhone | null;
  rol_id: any;
  identification_type_id: any;
  identification: any;
  address: any;
  user_status: any;
  city: any;
  password: any;
}

export class FormClient {
  id: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  phone: IPhone | null;
  phone_2: IPhone | null;
  rol_id: any;
  identification_type_id: any;
  identification: any;
  user_status: any;
  city: any;
  address: any;
  password: any;

  constructor(userData: IFormClientData) {
    this.id = userData.id;
    this.first_name = userData.first_name;
    this.last_name = userData.last_name;
    this.email = userData.email;
    this.phone = userData.phone;
    this.phone_2 = userData.phone_2;
    this.rol_id = userData.rol_id;
    this.identification_type_id = userData.identification_type_id;
    this.identification = userData.identification;
    this.user_status = userData.user_status;
    this.address = userData.address;
  }
}
