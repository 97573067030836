import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import UserView from "@/views/UserView.vue";
import DeterminerView from "@/views/DeterminerView.vue";
import isNewUser from "@/helpers/isNewUser";
import UserDetail from "@/views/UserDetail.vue";
import DeterminerDetail from "@/views/DeterminerDetail.vue";
import AgentsView from "@/views/AgentsView.vue";
import PermissionsView from "@/views/PermissionsView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/app",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/app/users",
    name: "Users",
    component: UserView,
  },
  {
    path: "/app/device",
    name: "Determiners",
    component: DeterminerView,
  },
  {
    path: "/app/device/:id",
    name: "DeterminerDetail",
    component: DeterminerDetail,
  },
  {
    path: "/app/users/:id",
    name: "UserDetail",
    component: UserDetail,
  },
  {
    path: "/app/agents",
    name: "AgentsView",
    component: AgentsView,
  },
  {
    path: "/app/settings",
    name: "PermissionsView",
    component: PermissionsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  if (to.path !== "/login") {
    isNewUser.state();
  }
});

export default router;
