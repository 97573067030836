import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_layout_koffe = _resolveComponent("layout-koffe")!

  return (_openBlock(), _createBlock(_component_layout_koffe, {
    api: _ctx.api,
    sidebar: true,
    position: "left",
    t: _ctx.$t,
    onOnSetLanguage: _ctx.onSetLanguage
  }, {
    default: _withCtx(() => [
      _createVNode(_component_RouterView)
    ]),
    _: 1
  }, 8, ["api", "t", "onOnSetLanguage"]))
}