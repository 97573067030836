import { Rol } from "../models/rol";
import { IUserRepository } from "../../ports/iUserRepository";

export class ListRolsUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(isAgent: boolean): Promise<Rol[]> {
    return this.userRepository.getRoles(isAgent);
  }
}
