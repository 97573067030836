import { INoteRepository } from "../../ports/iNoteRepository";
import { Note } from "../models/note";

export class ListNotesUseCase {
  constructor(private noteRepository: INoteRepository) {}

  async execute(id: string): Promise<Note[]> {
    return this.noteRepository.getNotes(id);
  }
}
