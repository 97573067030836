import axios from "@/helpers/axios";
import { IPaginatedResponse } from "../../../interfaces/IPaginatedResponse";
import { Determiner } from "../../domain/determiner";
import { Status } from "@/determiners/domain/status";
import { Type } from "@/determiners/domain/type";
import { User } from "@/determiners/domain/user";
import { DeterminerInfo } from "../../domain/determinerInfo";
import { IDeterminerRepository } from "@/determiners/ports/iDeterminerRepository";
import { Determiners } from "@/determiners/domain/formDevice";

export class DeterminerApiAdapter implements IDeterminerRepository {
  async getDeterminerById(id: number): Promise<Determiners> {
    const response = await axios.get(`/api/determiner/${id}/`);
    const determinerData = response.data;
    return new Determiners(determinerData);
  }
  async updateDeterminer(determiner: Determiner): Promise<Determiner> {
    const determinerId = determiner.id;
    const response = await axios.put(
      `/api/determiner/${determinerId}/`,
      determiner
    );
    const updatedDeterminerData = response.data;
    return new Determiner(updatedDeterminerData);
  }
  async getStatus(): Promise<Status[]> {
    const response = await axios.get(`/api/determinerState/`);
    const data: any[] = response.data;
    return data.map((v) => new Status(v));
  }
  async getType(): Promise<Type[]> {
    const response = await axios.get(`/api/determinerType/`);
    const data: any[] = response.data;
    return data.map((v) => new Type(v));
  }
  async getUser(): Promise<User[]> {
    const response = await axios.get(`/api/users/`);
    const data: any[] = response.data.results;
    return data.map((v) => new User(v));
  }
  async getDeterminers(
    url: string,
    adicionalHeader: Record<string, string> = {}
  ): Promise<IPaginatedResponse<Determiner>> {
    return axios.get(url, {
      headers: {
        ...adicionalHeader,
      },
    });
  }
  async getDeterminerInfo(): Promise<DeterminerInfo> {
    return axios.get("/api/determiner/determiner_info/");
  }
  async saveDeterminer(determiner: any): Promise<Determiner> {
    return axios.post("/api/determiner/", determiner);
  }
  async downloadFormat(): Promise<any> {
    const blob: any = { responseType: "blob" };
    return axios.get("/api/determiner/export-format/", {
      ...blob,
    });
  }
  async importDevices(data: any): Promise<any> {
    return axios.post("/api/determiner/import/", data);
  }
}
