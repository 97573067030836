export interface IPermissionsData {
  id: string;
  name: string;
  components: IPermissionsComponentsData[];
}

export interface IPermissionsComponentsData {
  id: string;
  name: string;
  is_check: boolean;
}

export class Permissions {
  id: string;
  name: string;
  components: IPermissionsComponentsData[];

  constructor(data: IPermissionsData | any) {
    this.id = data.id;
    this.name = data.name;
    this.components = data.components;
  }
}
