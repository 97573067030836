import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contacts-module__container" }
const _hoisted_2 = { class: "contacts-module__container__header" }
const _hoisted_3 = { class: "breadcrumbs" }
const _hoisted_4 = { class: "breadcrumbs__item" }
const _hoisted_5 = { class: "breadcrumbs__link" }
const _hoisted_6 = { class: "contacts-module__container__component" }
const _hoisted_7 = { class: "contact-info__container list_user-content" }
const _hoisted_8 = { class: "contact-info__content" }
const _hoisted_9 = { class: "sub-component__main-container" }
const _hoisted_10 = { class: "sub-component__left-container-web" }
const _hoisted_11 = { class: "headres-content" }
const _hoisted_12 = { class: "activities-and-comments__header__title-container__title sub-component__item__header font-l-bold" }
const _hoisted_13 = { class: "sub-component__right-container" }
const _hoisted_14 = { class: "headres-content" }
const _hoisted_15 = { class: "activities-and-comments__header__title-container__title sub-component__item__header font-l-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListPermissions = _resolveComponent("ListPermissions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.$t("main.permissions.permissions")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t("main.permissions.permissionsWeb")), 1)
              ]),
              _createVNode(_component_ListPermissions)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("main.permissions.PermissionsMobile")), 1)
              ]),
              _createVNode(_component_ListPermissions, { "is-mobile": true })
            ])
          ])
        ])
      ])
    ])
  ]))
}