// import { UserInfo } from "../models/userInfo";
import { IUserRepository } from "../../ports/iUserRepository";

export class UnasignedDeviceUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(id: string): Promise<boolean> {
    return this.userRepository.unasignedDevice(id);
  }
}
