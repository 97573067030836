import { City } from "../models/city";
import { IUserRepository } from "../../ports/iUserRepository";

export class ListCitiesUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(id: string): Promise<City[]> {
    return this.userRepository.getCitiesByDeprament(id);
  }
}
