import es from "./es";
import en from "./en";

export default {
  es: {
    ...es,
  },
  en: {
    ...en,
  },
};
