// src/interfaces/ICityData.ts
export interface ICityData {
  id: string;
  name: string;
  code_id: string;
}

export class City {
  id: string;
  name: string;
  code_id: string;

  constructor(data: ICityData | any) {
    this.id = data.id;
    this.name = data.name;
    this.code_id = data.code_id;
  }
}
