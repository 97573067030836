import common from "./common";
import components from "./components";

export default {
  es: {
    ...common.es,
    ...components.es,
  },
  en: {
    ...common.en,
    ...components.en,
  },
};
