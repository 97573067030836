import { User } from "../models/user";
import { IUserRepository } from "../../ports/iUserRepository";

export class AddUserUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(data: any): Promise<User> {
    const dataToSend = { ...data };
    dataToSend["rol_id"] = dataToSend["rol_id"][0]["id"];
    dataToSend["user_status"] = dataToSend["user_status"][0]["id"];
    dataToSend["identification_type_id"] =
      dataToSend["identification_type_id"][0]["id"];
    dataToSend["city"] = dataToSend["city"][0]["id"];
    return this.userRepository.saveUser(dataToSend);
  }
}
