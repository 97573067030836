import Cookies from "js-cookie";
const base_name = process.env.VUE_APP_BASE_COOCKIE;

export default {
  getCookie(name: string) {
    const cookieName = `${base_name}_${name}`;
    return Cookies.get(cookieName);
  },
  setCookie(name: string, value: string) {
    const cookieName = `${base_name}_${name}`;
    Cookies.set(cookieName, value, { expires: 1 });
  },
  setStorage(name: string, value: string) {
    const StorageName = `${base_name}_${name}`;
    localStorage.setItem(StorageName, value);
  },

  getStorage(name: string) {
    const StorageName = `${base_name}_${name}`;
    return localStorage.getItem(StorageName);
  },
};
