import { DeterminerInfo } from "./determinerInfo";
import { IDeterminerRepository } from "../ports/iDeterminerRepository";

export class GetDeterminerInfoUseCase {
  constructor(private determinerRepository: IDeterminerRepository) {}

  async execute(): Promise<DeterminerInfo> {
    return this.determinerRepository.getDeterminerInfo();
  }
  async executeBy(id: any): Promise<DeterminerInfo> {
    return this.determinerRepository.getDeterminerById(id);
  }
}
