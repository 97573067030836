import { UserInfo } from "../models/userInfo";
import { IUserRepository } from "../../ports/iUserRepository";

export class GetUserInfoUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(): Promise<UserInfo> {
    return this.userRepository.getUserInfo();
  }
  async executeBy(id: any): Promise<UserInfo> {
    return this.userRepository.getUserInfoById(id);
  }
}
