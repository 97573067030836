import { IdentificationType } from "../models/identificationType";
import { IUserRepository } from "../../ports/iUserRepository";

export class ListDeterminerUnasignedUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(): Promise<IdentificationType[]> {
    return this.userRepository.getDeterminatorUnasigned();
  }
}
