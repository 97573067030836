// src/interfaces/IDepartmentData.ts
export interface IDepartmentData {
  id: string;
  name: string;
  code_id: string;
}

export class Department {
  id: string;
  name: string;
  code_id: string;

  constructor(data: IDepartmentData | any) {
    this.id = data.id;
    this.name = data.name;
    this.code_id = data.code_id;
  }
}
