import { Country } from "../models/country";
import { IUserRepository } from "../../ports/iUserRepository";

export class ListCountriesUseCase {
  constructor(private userRepository: IUserRepository) {}

  async execute(): Promise<Country[]> {
    return this.userRepository.getCountries();
  }
}
