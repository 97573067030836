import axios from "@/helpers/axios";
import { IPaginatedResponse } from "../../../interfaces/IPaginatedResponse";
import { User } from "../../domain/models/user";
import { Rol } from "../../domain/models/rol";
import { Country } from "../../domain/models/country";
import { Department } from "../../domain/models/department";
import { City } from "../../domain/models/city";
import { Status } from "../../domain/models/status";
import { UserInfo } from "../../domain/models/userInfo";
import { IdentificationType } from "../../domain/models/identificationType";
import { IUserRepository } from "../../ports/iUserRepository";
import { Determiner } from "../../domain/models/determiner";

export class UserApiAdapter implements IUserRepository {
  async getUserById(id: number): Promise<User> {
    const response = await axios.get(`/api/users/${id}`);
    const userData = response.data;
    return new User(userData);
  }
  async getRoles(isAgent: boolean): Promise<Rol[]> {
    const response = await axios.get(`/api/users/rols/?is_agent=${isAgent}`);
    const data: any[] = response.data;
    return data.map((v) => new Rol(v));
  }
  async getIdentificationsType(): Promise<IdentificationType[]> {
    const response = await axios.get(`/api/users/identifications_type/`);
    const data: any[] = response.data;
    return data.map((v) => new IdentificationType(v));
  }
  async getDeterminatorUnasigned(): Promise<IdentificationType[]> {
    const response = await axios.get(`/api/determiner/unassignedlist/`);
    const data: any[] = response.data;
    return data.map((v) => new Determiner(v));
  }
  async getUsers(
    url: string,
    adicionalHeader: Record<string, string> = {}
  ): Promise<IPaginatedResponse<User>> {
    return axios.get(url, {
      headers: {
        ...adicionalHeader,
      },
    });
  }
  async getUserInfo(): Promise<UserInfo> {
    return axios.get("/api/users/user_info/");
  }
  async getUserInfoById(id: any): Promise<UserInfo> {
    return axios.get("/api/users/" + id + "/user_info_by_id/");
  }
  async getStatus(): Promise<Status[]> {
    const response = await axios.get(`/api/users/list_user_status/`);
    const data: any[] = response.data;
    return data.map((v) => new Status(v));
  }
  async saveUser(user: any): Promise<User> {
    return axios.post("/api/users/", user);
  }

  async updateUser(user: any): Promise<User> {
    return axios.put(`/api/users/${user.id}/`, user);
  }
  async asignDeterminer(
    idDeterminer: any,
    idUser: any,
    data: any
  ): Promise<any> {
    const formData = new FormData();
    formData.append("date", data["date"]);
    formData.append("value", data["value"]);
    formData.append("observation", data["observation"]);
    formData.append("number", data["number"]);
    if (data["invoice_path"] && data["invoice_path"] !== "null")
      formData.append("invoice_path", data["invoice_path"]);
    return axios.put(
      `/api/determiner/${idDeterminer}/assign_user/${idUser}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  async getCountries(): Promise<Country[]> {
    const response = await axios.get(`/api/locations/countries/`);
    const data: any[] = response.data;
    return data.map((v) => new Country(v));
  }
  async getDeparmentsByCountri(id: string): Promise<Department[]> {
    const response = await axios.get(`/api/locations/states/${id}/`);
    const data: any[] = response.data;
    return data.map((v) => new Department(v));
  }
  async getCitiesByDeprament(id: string): Promise<City[]> {
    const response = await axios.get(`/api/locations/cities/${id}/`);
    const data: any[] = response.data;
    return data.map((v) => new City(v));
  }
  async unasignedDevice(id: string): Promise<boolean> {
    const response = await axios.delete(`/api/determiner/${id}/unasigner/`);
    return response.status === 200;
  }
  async changePassword(data: any): Promise<any> {
    const response = await axios.put("/api/auth/change_password/", {
      ...data,
    });
    return response;
  }
  async downloadFile(id: string): Promise<Blob> {
    try {
      const response = await axios.get(
        `/api/determiner/determiners_by_user/${id}/download`,
        {
          responseType: "blob", // Indicar que la respuesta es un blob (archivo)
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      throw error;
    }
  }
}
