export interface IUserData {
  id: string;
  username: string;
}

export class User {
  id: string;
  username: string;

  constructor(data: IUserData | any) {
    this.id = data.id;
    this.username = data.username;
  }
}
