<template>
  <co-modal
    class="import-device"
    v-model:value="modal"
    size="md"
    :width="389"
    @close="close"
  >
    <template #title>
      <h1 class="title">
        {{ $t("main.determinerImport.import") }}
      </h1>
    </template>
    <template #body>
      <div class="content">
        <div class="item">
          <span>1</span>
          <label data-testid="link_downaload">
            {{ $t("main.determinerImport.download") }}
            <a class="download" @click="() => downloadFormat()">{{
              $t("main.determinerImport.here")
            }}</a>
            {{ $t("main.determinerImport.infoImport") }}
            <br />
          </label>
        </div>
        <br />
        <div class="item">
          <span>2</span>
          <label>{{ $t("main.determinerImport.attachFile") }}</label>
        </div>
        <div class="input">
          <co-xlsx
            data-testid="attach_file"
            @file="onReadFile"
            id="coxlsx"
            class="coxlsx"
            ref="coxlsx"
            accept=".csv,.xlsx"
            :headers="headers"
            delimiter=";"
          />
          <co-input
            v-model="file.name"
            class="co-input"
            size="sm"
            type="search"
            block
            disabled
            :error="isErrors"
            :completed="!isErrors"
          >
            <template #icon>
              <img
                v-if="file.name"
                :src="ICONS.CLOSE"
                alt="Search Icon"
                class="input__container-icon__img"
                @click="clear"
              />
              <div v-else></div>
            </template>
          </co-input>
          <!--Add activity-->
          <co-button
            data-testid="add_file"
            class="btn"
            size="sm"
            type="primary"
            @trigger-click="addFile"
          >
            <img class="icon" :src="ICONS.ATTACH" alt="icon_add" />
          </co-button>
        </div>
        <label
          data-testid="error_simple"
          class="error"
          v-if="isErrors && !fileErrors"
          >{{ errorMesage }}
        </label>
        <label
          data-testid="error_see"
          class="error"
          v-if="isErrors && fileErrors"
          >{{ errorMesage }}
          <a @click="dowloadErrors">{{
            $t("main.determinerImport.seeMore")
          }}</a></label
        >
        <label data-testid="success" class="success" v-if="isSuccess">{{
          $t("main.determinerImport.theFileWasUploadedSuccessfully")
        }}</label>
      </div>
    </template>
    <template #footer>
      <co-button
        data-testid="cancel"
        size="sm"
        type="cancel"
        @trigger-click="close"
      >
        <span>{{ $t("main.common.cancel") }}</span>
        <em class="mdi mdi-close"></em>
      </co-button>
      <co-button
        data-testid="import_file"
        size="sm"
        type="primary"
        :disabled="loading"
        @trigger-click="save"
      >
        <span data-testid="import">{{
          $t("main.determinerImport.import")
        }}</span>
        <em class="mdi mdi-check"></em>
      </co-button>
    </template>
  </co-modal>
</template>
<script lang="ts">
import { defineComponent, inject } from "vue";

/*Store imports*/
import { useCommonModal } from "@/stores/modals";
import { DeterminerApiAdapter } from "../adapters/api/determinerApiAdapter";
import { ImportDevicesUseCase } from "../domain/imporDeviceUseCase";
import { DownloadFormatDeviceUseCase } from "../domain/downloadFormatDeviceUseCase";
import { ICONS } from "koffee-senser-components";

import type { AxiosError } from "axios";

export default defineComponent({
  name: "ImportDevices",
  setup() {
    const toast: any = inject("toast");
    const can: any = inject("permissionByComponent");
    const modalStore = useCommonModal();
    return { modalStore, toast, can, ICONS };
  },
  data() {
    return {
      /*Module  behavior data*/
      loading: false,

      /*Validations data*/
      fileErrors: null as any | null,
      isErrors: false,
      isSuccess: false,
      errorMesage: "",

      file: { name: "", data: [] },

      /*data tooltip*/
      errorResponse: "" as string | never[],
      headers: {
        Código: "code_id",
        "Id de verificación": "verify_id",
        Serial: "serial",
        Modelo: "model",
        Tipo: "determiner_type_id",
        Estado: "status_id",
        "Tipo de identificación": "identification_type_id",
        "Número de identificación": "identification",
        "Numero de Guía": "track_number",
        "Fecha de envío": "send_date",
        "Fecha estimada de entrega": "estimate_delivery_date",
        "Fecha de entrega": "delivery_date",
      },
    };
  },
  computed: {
    modal: {
      get(): boolean {
        return this.modalStore.modals.showImportDevices;
      },
      set(v: boolean) {
        this.modalStore.changeMultiModalState("showImportDevices", v);
      },
    },
  },
  methods: {
    /*Modal state handler*/
    close() {
      this.modal = false;
      this.clear();
      this.$emit("onClose");
    },
    formatDate(dateTime: any) {
      if (typeof dateTime == "string") {
        const partes = dateTime.split("/");
        const dia = partes[0];
        const mes = partes[1];
        const anio = partes[2];
        // Formatear la fecha en el formato deseado
        const fechaFormateada = `20${anio}-${mes.padStart(
          2,
          "0"
        )}-${dia.padStart(2, "0")}`;
        return fechaFormateada;
      } else {
        // Suponiendo que dateTime es un número de serie de Excel con fecha y hora
        const baseDate = new Date(1899, 11, 30); // Fecha base de Excel
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const formattedDateTime = new Date(
          baseDate.getTime() + dateTime * millisecondsPerDay
        );
        // Formatear la fecha y hora como YYYY-MM-DD HH:mm:ss
        const year = formattedDateTime.getFullYear();
        const month = String(formattedDateTime.getMonth() + 1).padStart(2, "0");
        const day = String(formattedDateTime.getDate() + 1).padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
    },
    downloadFormat() {
      const determinerRepository = new DeterminerApiAdapter();

      const download = new DownloadFormatDeviceUseCase(determinerRepository);
      download.execute().then((v: any) => {
        const blob = v.data;
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "_blank";
        link.download = "device_format.xlsx";
        link.click();
      });
    },
    addFile() {
      const addfileManager = document.getElementById("coxlsx");
      if (addfileManager !== null) {
        addfileManager.click();
      }
    },
    onReadFile(v: any) {
      this.file = v;
    },
    clear() {
      this.isErrors = false;
      this.isSuccess = false;
      this.fileErrors = null;
      this.file = { name: "", data: [] };
    },
    formatDataSave() {
      const keys: any = this.headers;
      const data: any[] = [];
      this.file.data.forEach((v: any) => {
        const d: any = {};
        for (const key in keys) {
          // Verifica si la clave existe en el objeto v antes de asignarla
          d[keys[key]] = v[key] !== undefined ? v[key] : "";
        }
        for (const key in d) {
          if (d[key] === undefined || d[key] === null || d[key] === "") {
            console.log(d[key]);
          }
        }

        // Formatear fechas
        if (d["send_date"] !== "") {
          d["send_date"] = this.formatDate(d["send_date"]);
        } else {
          d["send_date"] = null;
        }
        if (d["estimate_delivery_date"] !== "") {
          d["estimate_delivery_date"] = this.formatDate(
            d["estimate_delivery_date"]
          );
        } else {
          d["estimate_delivery_date"] = null;
        }
        if (d["delivery_date"] !== "") {
          d["delivery_date"] = this.formatDate(d["delivery_date"]);
        } else {
          d["delivery_date"] = null;
        }

        data.push(d);
      });
      return data;
    },
    isValidFile() {
      if (!this.file.name) {
        this.isErrors = true;
        this.errorMesage =
          this.$t("main.determinerImport.noFileSelected") + "*";
        this.loading = false;
        return false;
      }
      if (
        this.file.name.split(".")[1] !== "xlsx" &&
        this.file.name.split(".")[1] !== "csv"
      ) {
        this.isErrors = true;
        this.errorMesage =
          this.$t("main.determinerImport.thisFileDoesNotConformFormat") + "*";
        this.loading = false;
        return false;
      }
      if (this.file.data.length == 0) {
        this.isErrors = true;
        this.loading = false;
        this.errorMesage =
          this.$t("main.determinerImport.thisFileIsEmpty") + "*";
        return false;
      }
      return true;
    },
    save() {
      this.loading = true;
      this.isErrors = false;
      this.isSuccess = false;
      this.fileErrors = null;
      this.errorMesage = "";
      if (!this.isValidFile()) {
        return true;
      }

      const data: any[] = this.formatDataSave();

      if (this.isErrors) {
        this.loading = false;
        return true;
      }
      const determinerRepository = new DeterminerApiAdapter();

      const importDevice = new ImportDevicesUseCase(determinerRepository);
      importDevice
        .execute(data)
        .then((v: any) => {
          if (v.data.file) {
            this.fileErrors = v.data.file;
            this.errorMesage = this.$t(
              "main.determinerImport.fileWasImportedButSomeRecordsFailed"
            );
            this.isErrors = true;
            this.isSuccess = false;
            this.loading = false;
          } else {
            this.fileErrors = null;
            this.isErrors = false;
            this.isSuccess = true;
            this.loading = false;
            this.toast({
              type: "success",
              title: this.$t("main.common.successfulProcess"),
              text:
                this.$t(
                  "main.determinerImport.yourProductsHasBeenImportedCorrectly"
                ) + ".",
              size: "sm",
            });
            setTimeout(() => {
              this.$emit("onSuccess");
              this.close();
            }, 1000);
          }
        })
        .catch((error: AxiosError<Array<string>, any>) => {
          this.fileErrors = null;
          this.isErrors = true;
          this.isSuccess = false;
          this.loading = false;
          let errorMessage = "";
          if (error.response?.status == 400) {
            errorMessage = error.response?.data
              .map((v) => this.$t(v, { verify: "verify_id" }))
              .join("<br>");
          } else {
            errorMessage = this.$t("main.common.toasts.unknown_error");
          }
          this.toast({
            type: "error",
            title: this.$t("main.common.toasts.error_title"),
            text: errorMessage,
            size: "sm",
          });
        });
    },
    dataURLtoFile(dataurl: any, filename: any) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    dowloadErrors() {
      const file = this.dataURLtoFile(this.fileErrors, "errors.xlsx");
      let blob = new Blob([file], { type: file.type });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank";
      link.download = `errors.xlsx`;
      link.click();
    },
  },
});
</script>
