<template>
  <div class="home-view">
    <div class="home-view__container">
      <h1 class="home-view__title">Bienvenido a Koffee Senser</h1>
      <span class="home-view__text">
        Aquí podrá gestionar la información de usuarios y determinadores.
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
});
</script>
