import { Determiner } from "./determiner";
import { IDeterminerRepository } from "../ports/iDeterminerRepository";
import { IPaginatedResponse } from "../../interfaces/IPaginatedResponse";

export class ListDeterminersUseCase {
  constructor(private determinerRepository: IDeterminerRepository) {}

  async execute(
    restApiUrl: any,
    axiosHeader: any
  ): Promise<IPaginatedResponse<Determiner>> {
    return this.determinerRepository.getDeterminers(restApiUrl, axiosHeader);
  }
}
